import { render, staticRenderFns } from "./DemographicsPanel.vue?vue&type=template&id=e0f59d14&scoped=true"
import script from "./DemographicsPanel.vue?vue&type=script&lang=js"
export * from "./DemographicsPanel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0f59d14",
  null
  
)

export default component.exports