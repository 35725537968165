const _ = require('lodash');


export default {

    sortObject(obj) {
        let listSortedObj = this.findAndOrderLists(obj)
        return this.sortObjectByKeys(listSortedObj)
    },

    /**
     * Recursively iterates over an object and any sub-objects, and sorts any arrays it finds.
     * If the arrays contain objects, it will sort by the first key it finds that has a value in the objects.
     * If the arrays contain primitives, it will sort them in ascending order.
     * @param {Object} obj the object to search and sort
     * @returns {Object} the sorted object
     */
    findAndOrderLists(obj) {
        let newObj = _.cloneDeep(obj)
        for (const key in newObj) {
            if (Array.isArray(newObj[key])) {
                if (typeof newObj[key][0] === 'object') {
                    let sortKey = Object.keys(newObj[key][0]).find((k) => newObj[key][0][k] !== null)
                    newObj[key] = this.sortArrayOfObjects(newObj[key], sortKey)
                }
                else newObj[key].sort()
            } else if (typeof newObj[key] === 'object') {
                this.findAndOrderLists(newObj[key])
            }
        }
        return newObj
    },

    /**
     * Sorts an object by its keys.
     * @param {Object} obj - the object to sort
     * @returns {Object} a new object with sorted keys
     */
    sortObjectByKeys(obj) {
        const sortedKeys = Object.keys(obj).sort()
        const sortedObj = {}
        for (const key of sortedKeys) {
            sortedObj[key] = obj[key]
        }
        return sortedObj
    },

    /**
     * Sorts an array of objects based on a key.
     * @param {Array<Object>} arr - the array to sort
     * @param {string} key - the key to sort by
     * @returns {Array<Object>} a new array with sorted objects
     */
    sortArrayOfObjects(arr, key) {
        return arr.sort((a, b) => {
            if (a[key] < b[key]) {
                return -1
            } else if (a[key] > b[key]) {
                return 1
            } else {
                return 0
            }
        })
    }


}


