<template>
    <div>
        <button @click="dialog=true" >{{displayName}}</button>
        <v-dialog v-model="dialog" width="600" :persistent="persistDialog">
            <fe-stepper-filter
                :title="title" style="height:600px;"
                @apply="emit" @close="dialog=false"
                :applyDisabled="!formValid || !enableFinalPanel"
            >

            <fe-stepper-panel width="100%" style="padding:20px;">
                <v-form lazy-validation v-model="formValid">
                    <v-layout row>
                        <v-flex xs6 class="pr-2">
                            <fe-date-picker
                                ref="start_date"
                                v-model="panelValues.start_date"
                                label="Incidents After"
                                :max="panelValues.end_date"
                            />
                        </v-flex>
                        <v-flex xs6>
                            <fe-date-picker
                                ref="end_date"
                                v-model="panelValues.end_date"
                                label="Incidents Before"
                                :min="panelValues.start_date"
                            />
                        </v-flex>
                    </v-layout>

                    <v-layout row>
                        <v-flex xs6 class="pr-2">
                            <fe-remote-combo
                                v-model="panelValues.incident_behavior_type"
                                label="Incident Type"
                                multiple moreText
                                url="incidentBehaviorType.php?action=get"
                                rootProperty="incident_behavior_type"
                            />
                        </v-flex>
                        <v-flex xs6>
                            <fe-remote-combo
                                v-model="panelValues.incident_behavior"
                                label="Code"
                                multiple moreText
                                url="incidentBehavior.php?action=get"
                                :params="panelValues.incident_behavior_type ? {
                                    incident_behavior_type_id: panelValues.incident_behavior_type.map( v => v.id )
                                } : null "
                                rootProperty="incident_behavior"
                            />
                        </v-flex>
                    </v-layout>

                    <v-layout row>
                        <v-flex xs6 class="pr-2">
                            <fe-remote-combo
                                v-model="panelValues.incident_location"
                                label="Location"
                                multiple moreText
                                url="incidentLocations.php?action=get"
                                rootProperty="incident_locations"
                                :sort="sortByName"
                            />
                        </v-flex>
                        <v-flex xs6>
                            <fe-remote-combo
                                v-model="panelValues.incident_response"
                                label="Response"
                                multiple moreText
                                url="incidentResponses.php?action=get"
                                rootProperty="incident_responses"
                            />
                        </v-flex>
                    </v-layout>

                    <v-layout row>
                        <v-flex xs6 class="pr-2">
                            <div class="theme--light v-label">Minimum Incidents</div>
                            <v-text-field
                                type="number"
                                flat solo dense
                                v-model="panelValues.minimum_count"
                                :error-messages="incidentCountError"
                                :rules="[ v => !v || parseInt(v) >= 0 || 'Value must be greater than or equal to 0' ]"
                            />
                        </v-flex>
                        <v-flex xs6>
                            <div class="theme--light v-label">Maximum Incidents</div>
                            <v-text-field
                                type="number"
                                flat solo dense
                                v-model="panelValues.maximum_count"
                                :error-messages="incidentCountError"
                                :rules="[ v => !v || parseInt(v) >= 0 || 'Value must be greater than or equal to 0' ]"
                            />
                        </v-flex>
                    </v-layout>

                    <v-layout row>
                        <v-flex xs6 class="pr-2">
                            <fe-remote-combo
                                label="School Year"
                                v-model="panelValues.school_year"
                                rootProperty="years"
                                url="qry.php?action=get&property=school_years"
                            ></fe-remote-combo>
                        </v-flex>
                        <v-flex xs6 class="mt-8">
                            <v-switch v-model="panelValues.restrict" label="Restrict Results" hide-details />
                        </v-flex>
                    </v-layout>
                </v-form>

            </fe-stepper-panel>

            <template #footer>
                <div class="text-truncate" style="padding:14px">{{breadcrumb}}</div>
            </template>

            </fe-stepper-filter>
        </v-dialog>
    </div>
    </template>

    <script>
    import * as Util from '../../Util'

    export default {
        name: 'IncidentPanel',
        rank: 1,

        data() {
            return {
                formValid: true,
                oldFilter: null,
                displayName: 'Incident',
                type: 'incident',
                dialog: false,
                panelValues: {
                    restrict: true,
                    school_year: null,
                    start_date: '',
                    end_date: '',
                    incident_behavior_type: null,
                    incident_behavior: null,
                    incident_location: [],
                    incident_response: null,
                    minimum_count: '',
                    maximum_count: '',
                },
                panelOpenState: {},
                persistDialog: false,
            }
        },

        watch: {
            dialog(v) {
                if(!v) this.oldFilter = null, this.clear()
                if (v) this.panelOpenState = this.$_.clone(this.panelValues)
            },
            computedPanelValues: {
                handler(newValue) {
                    if (Object.keys(this.panelOpenState).length !== 0) {
                        let objectsAreEqual = JSON.stringify(newValue) === JSON.stringify(this.panelOpenState)
                        this.persistDialog = !objectsAreEqual
                    }
                }
            }
        },

        computed: {
            title() {
                return this.oldFilter ? `Edit ${this.displayName} Filter` : `Create ${this.displayName} Filter`
            },
            enableFinalPanel() {
                return true
            },
            breadcrumb() {
                return `Incident`
            },
            incidentCountError() {
                return this.panelValues.minimum_count !== null &&
                    this.panelValues.maximum_count !== null
                    ? parseInt(this.panelValues.minimum_count) >
                      parseInt(this.panelValues.maximum_count)
                        ? 'Minimum Incidents Must Be Lower Than Maximum Incidents'
                        : null
                    : null
            },
            computedPanelValues() {
                return Object.assign({}, this.panelValues)
            }
        },

        methods: {
            clear() {
                this.panelValues = {
                    restrict: true,
                    school_year: null,
                    start_date: this.$refs.start_date.inputValue = '',
                    end_date: this.$refs.end_date.inputValue= '',
                    incident_behavior_type: [],
                    incident_behavior: [],
                    incident_location: [],
                    incident_response: [],
                    minimum_count: '',
                    maximum_count: ''
                }
            },
            load(oldFilter) {
                this.oldFilter = oldFilter
                this.dialog = true
                let ep = oldFilter.editorParams

                this.panelValues = {
                    restrict: ep.restrict,
                    school_year: ep.school_year,
                    start_date: ep.start_date,
                    end_date: ep.end_date,
                    incident_behavior_type: ep.incident_behavior_type,
                    incident_behavior: ep.incident_behavior,
                    incident_location: ep.incident_location,
                    incident_response: ep.incident_response,
                    minimum_count: ep.minimum_count,
                    maximum_count: ep.maximum_count,
                }
            },
            emit() {
                this.dialog = false
                let uniqueId = Util.UUIDv4()
                let name = this.breadcrumb
                let filter = {
                    type: this.type,
                    id: uniqueId,
                    restrict: this.panelValues.restrict,
                    report: true,
                    params: this.$_.pickBy({
                        start_date: this.panelValues.start_date,
                        end_date: this.panelValues.end_date,
                        incident_behavior_type_id: this.panelValues.incident_behavior_type?.map(x => x.id),
                        incident_behavior_id: this.panelValues.incident_behavior?.map(x => x.id),
                        incident_location_id: this.panelValues.incident_location?.map(x => x.id),
                        incident_response_id: this.panelValues.incident_response?.map(x => x.id),
                        minimum_count: this.panelValues.minimum_count,
                        maximum_count: this.panelValues.maximum_count,
                        school_year_id: this.panelValues.school_year?.id,
                    }, x => !this.$_.isBlank(x)),
                }
                Object.defineProperties(filter, {
                    editor: {value: this},
                    editorParams: {value: {
                        restrict: this.panelValues.restrict,
                        start_date: this.panelValues.start_date,
                        end_date: this.panelValues.end_date,
                        incident_behavior_type: this.panelValues.incident_behavior_type ? [...this.panelValues.incident_behavior_type] : null,
                        incident_behavior: this.panelValues.incident_behavior ? [...this.panelValues.incident_behavior] : null,
                        incident_location: this.panelValues.incident_location ? [...this.panelValues.incident_location] : null,
                        incident_response: this.panelValues.incident_response ? [...this.panelValues.incident_response] : null,
                        minimum_count: this.panelValues.minimum_count,
                        maximum_count: this.panelValues.maximum_count,
                        school_year: this.panelValues.school_year ? {...this.panelValues.school_year} : null,
                    }},
                    grouper: {value: {
                        id: uniqueId,
                        name: name,
                        type: 'oldEvenRange',
                        args: {property: `filters.${uniqueId}.value`},
                    }},
                    chipText: {value: name},
                    tooltipData: {value: this.$_.pickBy({
                        'Incidents After': this.panelValues.start_date,
                        'Incidents Before': this.panelValues.end_date,
                        'Incident Type': this.panelValues.incident_behavior_type?.map(x => x.name).join(', '),
                        'Code': this.panelValues.incident_behavior?.map(x => x.name).join(', '),
                        'Location': this.panelValues.incident_location?.map(x => x.name).join(', '),
                        'Response': this.panelValues.incident_response?.map(x => x.name).join(', '),
                        'Minimum': this.panelValues.minimum_count,
                        'Maximum': this.panelValues.maximum_count,
                        'Restrict': this.panelValues.restrict,
                        'School Year': this.panelValues.school_year?.name,
                    }, x => !this.$_.isBlank(x))}
                })
                this.$emit('input', filter, this.oldFilter)
            },
            sortByName(a, b) {
                return a.name < b.name ? -1 : 1
            },

        },
    }
    </script>

    <style lang="scss" scoped>
    </style>
