<template>
    <div>
        <button @click="dialog=true" >{{displayName}}</button>
        <v-dialog v-model="dialog" width="600" :persistent="persistDialog">
            <fe-stepper-filter
                :title="title" style="height:600px;"
                @apply="emit" @close="dialog=false"
                :applyDisabled="!formValid || !enableFinalPanel"
            >

                <fe-stepper-checkbox-panel
                    v-model="panelValues.forms"
                    :items="items" width="50%"
                />

                <v-divider vertical style="background-color:#E0E1E6"/>

                <fe-stepper-panel width="50%" style="padding:10px;">
                    <v-form v-if="enableFinalPanel" lazy-validation v-model="formValid">
                        <fe-remote-combo
                            v-model="panelValues.year_type"
                            :clearable="false"
                            :items="[
                                {id:'created', name:'Created'},
                                {id:'updated', name:'Updated'},
                            ]"
                        />
                        <fe-remote-combo
                            v-model="panelValues.school_year"
                            rootProperty="years"
                            url="qry.php?action=get&property=school_years"
                        />
                        <v-layout row>
                            <v-flex class="pr-2">
                                <v-switch v-model="panelValues.restrict" label="Restrict Results" hide-details />
                            </v-flex>
                            <v-flex>
                                <v-switch v-model="panelValues.require_all" label="Require All" hide-details />
                            </v-flex>
                        </v-layout>
                    </v-form>
                </fe-stepper-panel>

                <template #footer>
                    <div class="text-truncate" style="padding:14px">{{breadcrumb}}</div>
                </template>
            </fe-stepper-filter>
        </v-dialog>
    </div>
</template>

<script>
import * as Util from '../../Util'
import FilterUtil from '../../FilterPanelUtil'

export default {
    name: 'SmartformPanel',

    rank: 1,

    data() {
        return {
            formValid: true,
            oldFilter: null,
            displayName: 'smartFORM',
            type: 'form',
            dialog: false,
            items: [],
            panelValues: {
                restrict: true,
                year_type: {id:'created'},
                require_all: false,
                school_year: null,
                forms: [],
            },
            persistDialog: false,
            panelOpenState: {},
        }
    },

    watch: {
        dialog(v) {
            if(!v) this.oldFilter = null, this.clear()
            if (v) this.loadForms(), this.panelOpenState = this.$_.clone(this.panelValues)
        },
        computedPanelValues: {
            handler(newValue) {
                if (Object.keys(this.panelOpenState).length !== 0) {
                    let objectsAreEqual = JSON.stringify(FilterUtil.sortObject(newValue)) === JSON.stringify(FilterUtil.sortObject(this.panelOpenState))
                    this.persistDialog = !objectsAreEqual
                }
            }
        }
    },

    computed: {
        title() {
            return this.oldFilter ? `Edit ${this.displayName} Filter` : `Create ${this.displayName} Filter`
        },
        enableFinalPanel() {
            return !this.$_.isEmpty(this.panelValues.forms)
        },
        breadcrumb() {
            return this.panelValues.forms.map(x=>x.name)
                .filter(Boolean)
                .join(', ')
        },
        computedPanelValues() {
            return Object.assign({}, this.panelValues)
        }
    },

    methods: {
        clear() {
            this.panelValues = {
                restrict: true,
                year_type: {id:'created'},
                require_all: false,
                school_year: null,
                forms: [],
            }
        },
        load(oldFilter) {
            this.oldFilter = oldFilter
            this.dialog = true
            let ep = oldFilter.editorParams

            this.panelValues = {
                restrict: ep.restrict,
                forms: ep.forms,
                year_type: ep.year_type,
                require_all: ep.require_all,
                school_year: ep.school_year
            }
        },
        emit() {
            this.dialog = false
            let uniqueId = Util.UUIDv4()
            let name = this.panelValues.forms.map(x => x.name).join(', ')
            let filter = {
                type: this.type,
                id: uniqueId,
                restrict: this.panelValues.restrict,
                report: true,
                params: this.$_.pickBy({
                    school_year_id: this.panelValues.school_year?.id,
                    year_type: this.panelValues.year_type?.id,
                    form_id: this.panelValues.forms.map(x => x.id),
                    require_all: this.panelValues.require_all,

                }, x => !this.$_.isBlank(x)),
            }
            Object.defineProperties(filter, {
                editor: {value: this},
                editorParams: {value: {
                    restrict: this.panelValues.restrict,
                    forms: this.panelValues.forms ? [...this.panelValues.forms] : null,
                    year_type: this.panelValues.year_type,
                    require_all: this.panelValues.require_all,
                    school_year: this.panelValues.school_year ? {...this.panelValues.school_year} : null,
                }},
                grouper: {value: {
                    id: uniqueId,
                    name: name,
                    type: 'propertyArray',
                    args: {path: `filters.${uniqueId}`, property: 'value'},
                }},
                chipText: {value: name},
                tooltipData: {value: this.$_.pickBy({
                    'Forms': name,
                    'School Year By': this.panelValues.year_type?.name,
                    'School Year': this.panelValues.school_year?.name,
                    'Restrict': this.panelValues.restrict,
                    'Require All': this.panelValues.require_all,
                }, x => !this.$_.isBlank(x))}
            })
            this.$emit('input', filter, this.oldFilter)
        },
        loadForms() {
            this.$axios({
                url: 'form.php?action=get&property=form&active=1&ignore_permissions=1',
            }).then((response) => {
                if (response && response.data) {
                    this.items = response.data.forms.sort(this.formSort)
                }
            })
        },
        formSort(a,b) {
            if(a.name.toLowerCase() < b.name.toLowerCase()) return -1
            if(a.name.toLowerCase() > b.name.toLowerCase()) return 1
            return 0
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
