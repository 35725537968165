<template>
    <div>
        <button @click="dialog=true">{{ displayName }}</button>

        <v-dialog v-model="dialog" width="400" :persistent="persistDialog">
            <fe-stepper-filter
                :title="title"
                style="height:655px;"
                :applyDisabled="!formValid || !enableFinalPanel"
                @apply="emit"
                @close="dialog=false"
            >
                <fe-stepper-panel width="100%" style="padding:20px;">
                    <v-form lazy-validation v-model="formValid">
                        <fe-remote-combo
                            v-model="panelValues.occurrence_month_num"
                            label="Month(s)"
                            itemText="name"
                            itemValue="value"
                            :items="months"
                            :disabled="panelValues.occurrence_weekday_num && panelValues.occurrence_weekday_num.length > 0"
                            multiple
                            moreText
                        />

                        <fe-remote-combo
                            v-model="panelValues.occurrence_weekday_num"
                            label="Weekday(s)"
                            itemText="name"
                            itemValue="value"
                            :items="days"
                            :disabled="panelValues.occurrence_month_num && panelValues.occurrence_month_num.length > 0"
                            multiple
                            moreText
                        />

                        <fe-remote-combo
                            v-model="panelValues.filter_by"
                            label="Filter by"
                            :items="filter_by_options"
                            byId
                        />

                        <v-layout v-if="panelValues.filter_by === 'rate'" row>
                            <v-flex xs6 class="mr-2">
                                <div class="theme--light v-label">Min Rate</div>
                                <v-text-field
                                    v-model="panelValues.attendance_value_min"
                                    v-mask="'###'"
                                    append-icon="far fa-percent"
                                    :error-messages="minError"
                                    flat solo dense
                                />
                            </v-flex>

                            <v-flex xs6 class="">
                                <div class="theme--light v-label">Max Rate</div>
                                <v-text-field
                                    v-model="panelValues.attendance_value_max"
                                    v-mask="'###'"
                                    append-icon="far fa-percent"
                                    :error-messages="maxError"
                                    flat solo dense
                                />
                            </v-flex>
                        </v-layout>

                        <v-layout v-else row>
                            <v-flex xs6 class="mr-2">
                                <div class="theme--light v-label">Min Days Missed</div>
                                <v-text-field
                                    v-model="panelValues.attendance_value_min"
                                    v-mask="'###'"
                                    :error-messages="minError"
                                    flat solo dense
                                />
                            </v-flex>

                            <v-flex xs6 class="">
                                <div class="theme--light v-label">Max Days Missed</div>
                                <v-text-field
                                    v-model="panelValues.attendance_value_max"
                                    v-mask="'###'"
                                    :error-messages="maxError"
                                    flat solo dense
                                />
                            </v-flex>
                        </v-layout>

                        <v-layout row>
                            <v-flex xs6 class="mr-2">
                                <div class="theme--light v-label">Attendance Type</div>
                                <v-select
                                    v-model="panelValues.rate_type"
                                    itemText="name" itemValue="value"
                                    :items="attendance_rate_types"
                                    :disabled="attendance_rate_types && attendance_rate_types.length === 1"
                                    returnObject
                                    flat solo dense
                                />
                            </v-flex>

                            <v-flex xs6>
                                <div class="theme--light v-label">Min Days Students Enrolled</div>
                                <v-text-field
                                    v-mask="'###'"
                                    v-model="panelValues.min_possible_attendance_days"
                                    flat solo dense
                                />
                            </v-flex>
                        </v-layout>

                        <v-layout row>
                            <fe-remote-combo
                                label="School Year"
                                v-model="panelValues.school_year"
                                rootProperty="years"
                                url="qry.php?action=get&property=school_years"
                            />

                            <v-flex class="pr-2 mt-6">
                                <v-switch v-model="panelValues.restrict" label="Restrict Results" hide-details/>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </fe-stepper-panel>

                <template #footer>
                    <div class="text-truncate" style="padding:14px">{{ breadcrumb }}</div>
                </template>
            </fe-stepper-filter>
        </v-dialog>
    </div>
</template>

<script>
import * as Util from '../../Util'
import FilterUtil from '../../FilterPanelUtil'

export default {
    name: 'AttendancePanel',

    rank: 1,

    data() {
        return {
            formValid: true,
            oldFilter: null,
            displayName: 'Attendance',
            type: 'attendance',
            dialog: false,
            panelValues: {
                restrict: true,
                school_year: null,
                occurrence_month_num: [],
                occurrence_weekday_num: [],
                attendance_value_min: 0,
                attendance_value_max: 100,
                rate_type: null,
                filter_by: 'rate',
                min_possible_attendance_days: '',
            },
            panelOpenState: {},
            persistDialog: false,
            days: [
                {name: 'Monday', value: 0},
                {name: 'Tuesday', value: 1},
                {name: 'Wednesday', value: 2},
                {name: 'Thursday', value: 3},
                {name: 'Friday', value: 4},
                {name: 'Saturday', value: 5},
                {name: 'Sunday', value: 6}
            ],
            months: [
                {name: 'January', value: 1},
                {name: 'February', value: 2},
                {name: 'March', value: 3},
                {name: 'April', value: 4},
                {name: 'May', value: 5},
                {name: 'June', value: 6},
                {name: 'July', value: 7},
                {name: 'August', value: 8},
                {name: 'September', value: 9},
                {name: 'October', value: 10},
                {name: 'November', value: 11},
                {name: 'December', value: 12}
            ],
            attendance_rate_types: [],
            filter_by_options: [
                {name: 'Attendance Rate', id: 'rate'},
                {name: 'Days Missed', id: 'days'}
            ],
        }
    },

    computed: {
        title() {
            return this.oldFilter ? `Edit ${this.displayName} Filter` : `Create ${this.displayName} Filter`
        },
        enableFinalPanel() {
            return true
        },
        breadcrumb() {
            let min = this.panelValues.filter_by === 'rate' ? '%' : ''
            let max = this.panelValues.filter_by === 'rate' ? '%' : ' Days Missed'
            return `Attendance: ${this.panelValues.attendance_value_min}${min}-${this.panelValues.attendance_value_max}${max}`
        },
        maxError() {
            return this.panelValues.attendance_value_max === '' ||
            this.panelValues.attendance_value_max > 100
                ? 'Enter a valid percent value'
                : Number(this.panelValues.attendance_value_min) >
                Number(this.panelValues.attendance_value_max)
                    ? 'Maximum must be greater than minimum'
                    : null
        },
        minError() {
            return this.panelValues.attendance_value_min === '' ||
            this.panelValues.attendance_value_min < 0
                ? 'Enter a valid percent value'
                : Number(this.panelValues.attendance_value_min) >
                Number(this.panelValues.attendance_value_max)
                    ? 'Minimum must be less than maximum'
                    : null
        },
        computedPanelValues() {
            return Object.assign({}, this.panelValues)
        }
    },

    watch: {
        dialog(v) {
            if (!v) this.oldFilter = null, this.clear()
            if (v) this.panelOpenState = this.$_.clone(this.panelValues)
        },
        computedPanelValues: {
            handler(newValue) {
                if (Object.keys(this.panelOpenState).length !== 0) {
                    let objectsAreEqual = JSON.stringify(FilterUtil.sortObject(newValue)) === JSON.stringify(FilterUtil.sortObject(this.panelOpenState))
                    this.persistDialog = !objectsAreEqual
                }
            }
        },
    },

    created() {
        Promise.all([
            this.$axios.get(
                'preferences.php?action=get&property=district&code=ATTENDANCE_EQUIV_RATE'
            ),
            this.$axios.get(
                'preferences.php?action=get&property=district&code=ATTENDANCE_AFFECTED_RATE'
            )
        ]).then(rs => {
            if (rs[0].data.preferences.some(p => p.user_value === '1')) {
                this.attendance_rate_types.push({
                    name: 'SIS Reported',
                    value: 'days_equivalent'
                })
            }

            if (rs[1].data.preferences.some(p => p.user_value === '1')) {
                this.attendance_rate_types.push({
                    name: 'Full Day',
                    value: 'days_affected'
                })
            }

            this.panelValues.rate_type = this.attendance_rate_types[0]
        })
    },

    methods: {
        clear() {
            this.panelValues = {
                restrict : true,
                school_year : null,
                occurrence_month_num : [],
                occurrence_weekday_num : [],
                attendance_value_min : 0,
                attendance_value_max : 100,
                rate_type : this.attendance_rate_types[0],
                min_possible_attendance_days : '',
                filter_by : "rate"
            }
            this.persistDialog = false
        },

        load(oldFilter) {
            this.oldFilter = oldFilter
            this.dialog = true
            let ep = oldFilter.editorParams
            this.panelValues = {
                restrict : ep.restrict,
                school_year : ep.school_year,
                occurrence_month_num : ep.occurrence_month_num,
                occurrence_weekday_num : ep.occurrence_weekday_num,
                attendance_value_min : ep.attendance_value_min,
                attendance_value_max : ep.attendance_value_max,
                rate_type : ep.rate_type,
                filter_by: ep.filter_by,
                min_possible_attendance_days : ep.min_possible_attendance_days,
            }
        },

        emit() {
            this.dialog = false
            let uniqueId = Util.UUIDv4()
            let name = this.breadcrumb
            let filter = {
                type: this.type,
                id: uniqueId,
                restrict: this.panelValues.restrict,
                report: true,
                params: this.$_.pickBy({
                    occurrence_month_num: this.panelValues.occurrence_month_num?.map(x => x.value),
                    occurrence_weekday_num: this.panelValues.occurrence_weekday_num?.map(x => x.value),
                    attendance_value_min: this.panelValues.attendance_value_min,
                    attendance_value_max: this.panelValues.attendance_value_max,
                    rate_type: this.panelValues.rate_type.value,
                    filter_by: this.panelValues.filter_by,
                    min_possible_attendance_days: this.panelValues.min_possible_attendance_days,
                    school_year_id: this.panelValues.school_year?.id,
                }, x => !this.$_.isBlank(x)),
            }

            let minProp = this.panelValues.filter_by === 'rate' ? 'Min Rate' : 'Min Days Missed'
            let maxProp = this.panelValues.filter_by === 'rate' ? 'Max Rate' : 'Max Days Missed'

            Object.defineProperties(filter, {
                editor: {value: this},
                editorParams: {
                    value: {
                        restrict: this.panelValues.restrict,
                        occurrence_month_num: this.panelValues.occurrence_month_num ? [...this.panelValues.occurrence_month_num] : null,
                        occurrence_weekday_num: this.panelValues.occurrence_weekday_num ? [...this.panelValues.occurrence_weekday_num] : null,
                        attendance_value_min: this.panelValues.attendance_value_min,
                        attendance_value_max: this.panelValues.attendance_value_max,
                        rate_type: {...this.panelValues.rate_type},
                        filter_by: this.panelValues.filter_by,
                        min_possible_attendance_days: this.panelValues.min_possible_attendance_days,
                        school_year: this.panelValues.school_year ? {...this.panelValues.school_year} : null,
                    }
                },
                grouper: {
                    value: {
                        id: uniqueId,
                        name: name,
                        type: 'controller',
                        args: {property: `filters.${uniqueId}.value`},
                    }
                },
                chipText: {value: name},
                tooltipData: {
                    value: this.$_.pickBy({
                        'Month(s)': this.panelValues.occurrence_month_num?.map(x => x.name).join(', '),
                        'Weekday(s)': this.panelValues.occurrence_weekday_num?.map(x => x.name).join(', '),
                        'Attendance Type': this.panelValues.rate_type.name,
                        [minProp]: this.panelValues.attendance_value_min,
                        [maxProp]: this.panelValues.attendance_value_max,
                        'Min Days Enrolled': this.panelValues.min_possible_attendance_days,
                        'Restrict': this.panelValues.restrict,
                        'School Year': this.panelValues.school_year?.name,
                    }, x => !this.$_.isBlank(x))
                }
            })

            this.$emit('input', filter, this.oldFilter)
        },
    }
}
</script>

<style lang="scss" scoped></style>
