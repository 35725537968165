<template>
    <div :style="collectionChart ? {margin: '5px'} : null">
        <div
            v-if="insight.show && !minimal"
            class="insight-container"
        >
            <div class="d-flex">
                <div class="section-title my-2">{{ config.title }} Insights</div>
                <fe-icon-btn class="ml-auto" useIcon="fa-times" @click="insight.show=false"/>
            </div>

            <div v-if="!insight.data.length" class="insight">No Attendance Found</div>

            <div v-else v-for="(d,i) in insight.data" :key="`insight-`+i" class="insight">
                <div class="insight-label">{{ d.name }}</div>
                The <b>average attendance rate</b> is
                <v-chip>{{ parseFloat(d.average).toFixed(2) }}%</v-chip>
                .
                <span class="insight-low">{{ d.minLabel }}</span> has the <b>lowest rate</b> of
                <v-chip color="error">{{ parseFloat(d.minValue).toFixed(2) }}%</v-chip>
                , while
                <span class="insight-high">{{ d.maxLabel }}</span> has the <b>highest rate</b> of
                <v-chip color="success">{{ parseFloat(d.maxValue).toFixed(2) }}%</v-chip>
            </div>
        </div>

        <chart
            ref="attendanceChart"
            class="mr-2"
            :config="localConfig"
            :collectionChart="collectionChart"
            :pinTitle.sync="getTitle"
            @chartClicked="doClick"
            :data-test="dataTestName"
        >
            <template #chart-title>
                <div ref="title" v-html="getTitle"></div>
            </template>

            <template #before-menu v-if="!minimal">
                <pin-dialog
                    v-if="pinDialog.show"
                    v-model="pinDialog.show"
                    :pinParams="pinParams"
                    :title="pinDialog.title"
                />

                <fe-icon-btn v-if="showInsight" useIcon="fas fa-lightbulb-on" @click="doInsight"/>

                <fe-icon-btn
                    v-if="pinnable"
                    useIcon="fas fa-thumbtack"
                    @click="pinDialog.show=true; pinDialog.title = `Attendance `+config.title"
                />

                <fe-icon-btn v-if="!expanded" useIcon="fas fa-expand-alt" @click="doExpand"/>
            </template>

            <template v-if="collectionChart" #after-chart>
                <div v-if="appliedFilters.length" class="d-flex justify-center chart-with-filters mb-3">
                    <template v-for="filter in subtitleFilters">
                        <fe-chip>{{ filter }}</fe-chip>
                    </template>

                    <span
                        v-if="moreFilters"
                        class="show-more-filters-btn"
                        @click="showMoreFilters = !showMoreFilters"
                    >
                        <u>{{ showMoreFilters ? 'Show More' : 'Show Less' }}</u>
                    </span>
                </div>
            </template>
        </chart>

        <div v-if="!collectionChart" class="d-flex justify-center">
            <template v-for="filter in subtitleFilters">
                <fe-chip>{{ filter }}</fe-chip>
            </template>

            <span
                v-if="moreFilters"
                class="show-more-filters-btn"
                @click="showMoreFilters = !showMoreFilters"
            >
                <u>{{ showMoreFilters ? 'Show More' : 'Show Less' }}</u>
            </span>
        </div>
    </div>
</template>

<script>
import PinDialog from '@/components/common/PinDialog'
import Chart from "./Chart";

export default {
    name: 'AttendanceChart',

    components: {Chart, PinDialog},

    props: {
        config: {required: true},
        chartParams: {},
        property: {type: String, default: 'chart'},
        stacked: {type: Boolean, default: true},
        height: {default: 300},
        showInsight: {type: Boolean, default: true},
        pinnable: {type: Boolean, default: true},
        pinTitle: {type: String, default: null},
        minimal: {type: Boolean, default: false},
        params: {type: Object},
        collectionChart: {type: Boolean, default: false},
        expanded: {type: Boolean, default: false},
        appliedFilters: {
            type: Array,
            default() {
                return []
            }
        },
        dataTestName: {type: String}
    },

    data() {
        return {
            insight: {
                show: false,
                text: ''
            },
            pinDialog: {
                show: false,
                title: ''
            },
            tooltip: function () {
                return this.series.name + " " + this.x + " " + this.y
            },
            categories: [],
            localConfig: {},
            yAxis: {
                max: undefined, min: undefined
            },
            event: null,
            pinName: null,
            showMoreFilters: true,
            moreFilters: false,
            filterArr: []
        }
    },

    computed: {
        getTitle: {
            get() {
                this.pinName = this.pinName || this.pinTitle || this.config.title
                return this.pinName
            },
            set(v) {
                this.pinName = v
            }
        },

        pinParams() {
            let p = {
                ...{dashboard_saved_search_type_id: this.property === 'period_charts' ? 8 : 7},
                ...this.config.params
            }
            delete p.saved_search_id
            return p
        },

        subtitleFilters() {
            if (this.appliedFilters.length) {
                let filterArr = this.appliedFilters
                this.moreFilters = filterArr.length > 8
                return this.showMoreFilters && this.moreFilters ? filterArr.slice(0, 3) : filterArr
            } else if (this.filterArr.length) {
                return this.filterArr
            }
        }
    },

    watch: {
        config(v) {
            if (v && this.pinnable) {
                this.buildSeries()
            }
        }
    },

    mounted() {
        this.$emit('all-charts', this.$refs.attendanceChart)
        this.buildSeries()
    },

    methods: {
        doClick(event, point) {
            let rec = this.config.data.find(r => {
                return r[this.config.key] === point.category //? point.category : event.point.category
            })

            this.$emit('chartClicked', event, rec, point)
        },

        doExpand() {
            let c = {
                config: this.config,
                chartParams: this.chartParams,
                property: this.property,
                stacked: this.stacked,
                height: window.innerHeight - 250,
                showInsight: this.showInsight,
                pinnable: false,
                expanded: true,
                appliedFilters: this.appliedFilters,
            }
            this.$store.commit('global/addDockableWindow', {
                name: this.getTitle,
                component: 'attendance-chart',
                attrs: c
            })
        },

        doInsight() {
            let data = []
            this.localConfig.series.forEach(serie => {
                if (serie.data.length) {
                    let min = {
                        index: null,
                        value: null
                    }
                    let max = {
                        index: null,
                        value: null
                    }
                    let avg = 0

                    serie.data.forEach((d, i) => {
                        if (min.value === null || d < min.value) {
                            min.index = i;
                            min.value = d
                        }
                        if (max.value === null || d > max.value) {
                            max.index = i;
                            max.value = d
                        }
                        avg += d
                    })
                    data.push({
                        name: serie.name,
                        average: (avg / serie.data.length),
                        minLabel: this.categories[min.index],
                        minValue: min.value,
                        maxLabel: this.categories[max.index],
                        maxValue: max.value,
                    })
                }
            })

            this.insight.data = data
            this.insight.show = true
        },

        buildSeries() {
            let series = []
            this.config.data.forEach(rec => {
                this.categories.push(rec[this.config.key])
            })

            if (this.config && this.config.data) {
                if (this.property === 'period_charts') {
                    series.push({
                        type: 'column',
                        name: 'Period Count',
                        data: []
                    })
                } else {
                    let added = {}
                    this.config.fields.forEach(y => {
                        if (y !== this.config.key && !added[y]) {
                            series.push({
                                name: y,
                                data: [],
                                marker: {
                                    symbol: 'circle',
                                    radius: 4
                                }
                            })
                            added[y] = 1
                        }
                    })
                }

                series.forEach(serie => {
                    if (this.categories.length > 0) {
                        this.categories.forEach(cat => {
                            let rec = this.config.data.find(data => data[this.config.key] === cat)
                            let rate = rec ? parseFloat(rec[serie.name]) : 0
                            serie.data.push(rate)

                            if (this.yAxis.min === undefined || rate < this.yAxis.min) this.yAxis.min = Math.floor(rate)
                            if (this.yAxis.max === undefined || rate > this.yAxis.max) this.yAxis.max = rate
                        })
                    }
                })

            }
            this.localConfig = this.buildChart(series)
        },

        buildChart(series) {
            let me = this

            let cfg = {
                chart: {
                    zoomType: 'xy',
                    marginTop: me.minimal ? 80 : undefined,
                    marginLeft: me.minimal ? 60 : undefined,
                    marginRight: me.minimal ? 10 : undefined,
                    marginBottom: me.minimal ? 110 : undefined,
                    type: 'column',
                    events: {
                        load: function() {
                            const series = this.series
                            series.forEach(s => {
                                let seriesName = me.config.title.split(' ')
                                seriesName.shift()
                                s.group.element.setAttribute('data-test', `absence-count-by-${seriesName.join('').trim().toLowerCase()}`)
                            })
                        }
                    }
                },
                title: {
                    useHTML: true,
                    align: 'left',
                    style: {
                        'color': '#7E8494',
                        'border-bottom': '1px solid #E0E1E6',
                        'display': 'block',
                        'width': '100%'
                    },
                    text: ''
                },
                plotOptions: {
                    line: {
                        marker: {
                            symbol: 'circle',
                            radius: 4
                        }
                    },
                    series: {
                        cursor: 'pointer',
                        stacking: '',
                        minPointLength: 0
                    }

                },
                yAxis: {
                    max: undefined,
                    min: undefined,
                    endOnTick: false,
                    title: {
                        text: me.property === 'period_charts' ? 'Periods Missed' : 'Rate'
                    },
                    labels: {
                        style: {
                            fontSize: me.minimal ? '10px' : '14px'
                        }
                    }
                },
                xAxis: {
                    categories: me.categories,
                    labels: {
                        useHTML: true,
                        style: {
                            fontSize: me.minimal ? '10px' : '14px',
                            whiteSpace: 'nowrap'
                        },
                        formatter() {
                            let long = this.value.length > 15
                            let val = (this.value).substring(0, 15)
                            return long ? val + '...' : val
                        },
                    }
                },
                series: series,
                credits: {
                    enabled: false
                },
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                legend: {
                    align: 'left',
                    shadow: false,
                },
                dash_id: null,
                saved_search_id: null,
                index: null
            }
            if (this.property !== 'period_charts') {
                cfg.yAxis.max = Math.min(this.yAxis.max + 10, 100)
                cfg.chart.type = 'line'
            }
            cfg.yAxis.min = this.property === 'period_charts' ? 0 : this.yAxis.min
            cfg.yAxis.max = this.yAxis.max
            cfg.title.text = this.getTitle
            cfg.chart.height = this.height
            if (this.params && this.params.dashboard_id) cfg.dash_id = this.params.dashboard_id
            if (this.params && this.params.saved_search_id) cfg.saved_search_id = this.params.saved_search_id
            if (this.params && this.params.index) cfg.index = this.params.index

            return cfg
        }
    },
}
</script>

<style lang="scss" scoped>
.insight-container {
    margin: 16px;
    position: absolute;
    z-index: 5;
    top: 0;
    background: white;
    border: 2px solid #f3f4fb;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.25);
    width: 97%;
}

.insight {
    margin-bottom: 16px;

    &-low {
        color: red;
    }

    &-high {
        color: green;
    }

    &-label {
        color: var(--v-primary-base);
        font-weight: bold;
    }
}

.show-more-filters-btn {
    align-items: center;
    display: flex;
    color: #006C90;
    font-size: 12px;
    cursor: pointer;
}
</style>
