<template>
    <v-app>
        <ApplicationTimeout />
        <fe-snackbar :timeout="5000" />

        <navigation-bar
            v-if="!hideNav"
            flat
            :showBell="!restrictedUI"
            :showStudentSearch="!restrictedUI"
            :showEnvelope="!restrictedUI"
            @top-nav="topNavClick"
            @clear-selected="clearActiveBtn"
            @np-loading="loadingNP"
        />

        <fe-dialog
            v-if="districtMessage.show"
            title="District Login Message"
            width=600
            acceptButtonText="Okay"
            @accept="districtMessage.show=false"
            @dismiss="districtMessage.show=false"
            :body="sessionUser.district.login_message"
        />

        <slideout-panel>
            <intervention-creator/>
        </slideout-panel>

        <v-navigation-drawer v-if="!hideNav" id="left-nav-main" v-model="showSlider" permanent app :width="68" floating class="left-nav-main ec-left-nav-main">
            <template v-for="item in items.filter(r => !r.hidden)">
                <v-btn
                    text
                    v-if="item.type === 'icon'"
                    :class="{ 'btn-expand':  item.type=='icon', 'selected-icon': activeBtn && item.id==activeBtn.name }"
                    @click="navigation(item)" :key="item.id"
                    :disabled="item.disabled"
                    :data-test="item.name ? `nav-${item.name.toLowerCase().replace(/ /g, '-')}` : ''"
                >
                    <div class="nav-icon">
                        <v-icon :id="item.id" v-if="item.id !== 's3d' && !item.stacked">{{ item.icon }}</v-icon>
                        <span v-else v-html="$ecIcons(item.icon, item.stacked)"></span>
                    </div>
                    <span>{{ item.name }}</span>
                </v-btn>

                <v-menu offset-x v-else-if="item.type === 'slider' && !item.route" transition="slide-x-transition">
                    <template v-slot:activator="{on}">
                        <v-btn
                            text
                            :class="{
                                'btn-expand':  item.type=='icon',
                                'selected-icon': activeBtn && item.id==activeBtn.name
                            }"
                            :key="item.id"
                            v-on="on"
                            :disabled="item.disabled"
                            :data-test="item.name ? `nav-${item.name.toLowerCase().replace(/ /g, '-')}` : ''"
                        >
                            <div class="nav-icon">
                                <v-icon :id="item.id" v-if="item.id !== 's3d'">{{ item.icon }}</v-icon>
                                <div :id="item.id" v-else class="btn-div"><strong>3D</strong></div>
                            </div>
                            <span>{{ item.name }}</span>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item v-for="opt in item.items.filter(f=>!f.disabled)" :key="`opt-`+opt.id" v-if="item.items" @click="handleMenuClick(opt)">
                            <v-list-item-title style="font-size: 14px">{{opt.name}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <v-divider v-else-if="item.type === 'divider'" style="margin: 8px -8px; min-width:68px;" :key="item.id"></v-divider>
                <v-spacer v-else :key="item.id"></v-spacer>
            </template>
        </v-navigation-drawer>

        <fe-mask v-show="loading" :showLoader="true" />
        <fe-overlay v-if="npLoading" loader text="Please wait..." color="white"/>

        <v-main class="no-scroll">
            <fe-student-card
                v-if="studentCard && studentCard.show"
                ref="studentCard"
                style="z-index: 10; position: absolute;"
                :style="[{left: studentCard.left, top: studentCard.top}]"
                :studentUrl="studentCard.studentUrl"
                :imageUrl="studentCard.imageUrl"
                v-show="studentCard.show"
                firstNameField="fname"
                lastNameField="lname"
                schoolField="school_name"
                gradeField="grade_desc"
                genderField="gender"
                @close="() => {}"
            ></fe-student-card>

            <tag-student v-if="studentsToTag && studentsToTag.show" v-model="studentsToTag.show" :students="studentsToTag.students"/>

            <fe-window
                v-for="(comp, i) in visibleWindows"
                v-bind="comp.windowOptions"
                :edge="3"
                v-show="!comp.docked"
                :title="comp.name"
                :key="`dockable-window-${comp.dockedWindowId}`"
                headerColor="white"
                headerTextColor="black"
                @minimize="toggleWindow(true, comp.dockedWindowId)"
                @snap="snapWindow(comp, $event)"
            >
                <template v-if="comp.titleSlotComponent" #title>
                    <component :is="comp.titleSlotComponent" />
                </template>
                <window-handler :config="comp">
                    <div class="flex-fill flex-column" :style="comp.parentStyle ? { height: 'calc(100% - 60px)', ...comp.parentStyle } : { height: '100%' }">
                        <component
                            :ref="`dockable-window-${comp.dockedWindowId}-component`"
                            v-observe-visibility="comp.visibilityChanged"
                            :is="comp.component"
                            v-bind="comp.attrs"
                            v-on="comp.events"
                        />
                    </div>
                </window-handler>
            </fe-window>

            <fe-dialog
                :width="createDialog.width ? createDialog.width : 500"
                v-if="createDialog.show"
                @dismiss="createDialog.show=false"
                @close="createDialog.show=false"
                persistent
                :title="createDialog.title ? createDialog.title : 'Change Me'"
                :footer="false"
                :header="false"
            >
                <div class="d-flex flex-column flex-fill pt-6">
                    <component :is="createDialog.component" v-bind="createDialog.attrs"/>
                </div>
            </fe-dialog>

            <meeting-creation
                v-if="meetingsDialog.show || meetingsDialog.created"
                :editing="meetingsDialog.created"
                v-on="meetingsDialog.events"
            />

            <edit-action-item v-if="actionItemDialog" @dismiss="onDismissActionItemDialog" standalone/>

            <fe-dialog
                id="fb-dialog"
                v-if="interventionDialog.show"
                title="Dual Product Options"
                dismissButtonText="Cancel"
                acceptButtonText="Continue"
                :acceptButtonDisabled="interventionDialog.includefb && fbpm.id < 1"
                width="600px"
                persistent
                @accept="createIntervention(interventionDialog.includefb)"
                @dismiss="interventionDialog.show=false"
                @close="interventionDialog.show=false"
            >
                <div class="d-flex flex-column flex-fill">
                    <div class="product-alert">Your district gives you access to FastBridge.</div>

                    <div class="mt-3 mb-5 ml-3">Do you want to link to a FastBridge progress monitoring plan?</div>

                    <v-radio-group v-model="interventionDialog.includefb" :mandatory="true">
                        <v-radio class="pendo-fb-no" label="No, I plan to use eduCLIMBER only" :value="false"/>
                        <v-radio class="pendo-fb-yes" label="Yes, I plan to use FastBridge for progress monitoring" :value="true" @change="loadFbPlans"/>
                    </v-radio-group>

                    <div v-if="interventionDialog.includefb" class="ml-10" style="margin-top: -7px">
                        <fe-remote-combo
                            ref="selectPlans"
                            :items="fbPlans"
                            itemText="intervention_name"
                            itemValue="id"
                            v-model="fbpm"
                            :rules="this.$fieldValidators('select', null, {required: true})"
                        />
                    </div>

                    <div v-if="fbpm.id === 0" class="fb-warning">
                        <i class="fas fa-exclamation-triangle"/>
                        <span class="fb-warning-text">It is highly recommended that you set up a plan in FastBridge first if you plan to use both eduCLIMBER and FastBridge. We are working on improving our integration and it will be more seamless in the future.</span>
                    </div>
                </div>
            </fe-dialog>

            <v-container ref="mainContainer" fluid fill-height class="main-container" v-resize="resize">
                <v-layout column fill-height style="overflow: auto">
                    <!-- <v-layout row v-if="!hideNav" shrink class="left-nav-main">
                        <v-navigation-drawer v-model="showSlider" absolute hide-overlay
                            :class="showSlider ? 'left-nav-drawer' : ''">
                            <v-layout fill-height column v-if="activeBtn && activeBtn.name === 'plus'">
                                this is for create / enter
                            </v-layout>
                            <v-layout fill-height column v-if="activeBtn && activeBtn.name === 'star'">
                                this is for starred / recent
                            </v-layout>
                        </v-navigation-drawer>
                    </v-layout> -->

                    <!-- <v-layout column > -->
                        <!-- {{ leftNavHeight }} -->
                        <keep-alive>
                            <router-view></router-view>
                        </keep-alive>
                    <!-- </v-layout> -->

                </v-layout>
            </v-container>

            <v-menu v-if="!hideNav"
                offset-y
                top
            >
                <template v-slot:activator="{ on }">
                    <v-btn small v-on="on" style="border-radius: 50%;" fab fixed right bottom :disabled="dockableWindows.filter(r => { return r.docked }).length === 0" data-test="minimized-windows-fab">
                        <v-badge
                            v-if="minimizedWindows.length > 0"
                            color="pink"
                            :content="minimizedWindows.length"
                            bordered
                            label="count"
                            class="minimize-count"
                        >
                        </v-badge>

                        <v-icon style="font-size: 16px;">
                            fas fa-layer-group
                        </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(win, i) in minimizedWindows" @click="toggleWindow(false, win.dockedWindowId)" :key="`win-${i}`" :data-test="`overlay-item-${i}`">
                        <v-list-item-content>
                            {{ win.name }}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>

            <search-export-data v-if="searchExport.show"
                v-on="searchExport.events"
                :value="{}"
            />

            <export-data v-if="exportData.show"
                 :value="exportData.rec"
                 @close="exportData.show=false"
            />
        </v-main>
    </v-app>
</template>

<script>
import NavigationBar from '@/components/navigation/NavigationBar'
import {mapState} from 'vuex'
import ApplicationTimeout from '@/util/ApplicationTimeout'

// Objects that can be dockable
import WindowHandler from '@/components/common/WindowHandler'
import SmartFormTemplate from '@/components/modules/smartforms/template/SmartFormTemplate'
import SmartForm from '@/components/modules/smartforms/SmartForm'
import AssessmentTrendline from '@/components/charts/AssessmentTrendline'
import AcademicProgressMonitoring from '@/components/charts/AcademicProgressMonitoring'
import BigFive from '@/components/modules/incidents/BigFive'
import BigFiveChart from '@/components/charts/BigFive'
import RiskRatio from "@/components/modules/incidents/RiskRatio"
import BenchmarkChart from '@/components/charts/BenchmarkChart'
import InterventionCreator from '@/components/modules/intervention/creation/Index'
import UserGuides from '@/components/modules/userguides/Index'
import ValidateUpload from '@/components/modules/manage/uploads/Validate'
import UploadDetail from '@/components/modules/manage/uploads/UploadDetail'
import ScoreEditor from '@/components/modules/scores/Index'
import AssessmentHistory from '@/components/modules/studentprofile/charts/AssessmentHistory'
import IFrameRenderer from '@/components/common/IFrameRenderer'
import EditActionItem from '@/components/modules/actionItems/EditActionItem'
import Calendar from '@/components/modules/calendar/Index'
import InterventionWorkspace from '@/components/modules/intervention/workspace'
import InterventionParticipation from '@/components/modules/intervention/implementation/Participation'
import AttendanceReporting from '@/components/modules/attendanceReporting'
import EnrollmentReporting from '@/components/modules/enrollmentReporting'
import StudentAttendance from '@/components/modules/attendanceReporting/StudentAttendance'
import PeriodCounts from '@/components/modules/attendanceReporting/PeriodCounts'
import AttendanceChart from '@/components/charts/AttendanceChart'
import IncidentOverview from '@/components/modules/incidents/IncidentOverview'
import IncidentCharting from '@/components/modules/incidents/IncidentCharting'
import TagStudent from '@/components/common/TagStudent'
import OpenIncidents from "@/components/modules/incidents/OpenIncidents"
import MyIncidents from "@/components/modules/incidents/MyIncidents"
import InterventionDrilldown from '@/components/modules/intervention/analysis/Drilldown'
import StudentMonitorScores from '@/components/modules/intervention/monitor/StudentMonitorScores'
import BehaviorProgressMonitoring from '@/components/charts/BehaviorProgressMonitoring'
import CreateScatterPlot from '@/components/modules/scatterplot/Create'
import ScatterPlot from '@/components/modules/scatterplot/'
import EndingInterventions from "@/components/modules/intervention/EndingInterventions"
import ChartDrilldown from '@/components/charts/ChartDrilldown'
import MeetingCreation from '@/components/modules/meetings/creation'
import PointSheetMonitoring from "@/components/modules/intervention/PointSheetMonitoring"
import DataWall from '@/components/modules/datawall/Index.vue'
import StackedChart from "@/components/charts/StackedChart"
import AcademicProgressMonitoring2 from "@/components/charts/AcademicProgressMonitoring2"
import FormReport from '@/components/modules/smartforms/reporting/FormReport'
import Slo from "@/components/modules/slo/Index"
import SloCreationDatawall from "@/components/modules/slo/creation/datawall/Index"
import SloCreationCharting from "@/components/modules/slo/creation/charting/Index"
import ObservationCreator from '@/components/modules/observations/creation/Index'
import StandardObservation from '@/components/modules/observations/creation/StandardObservation'
import SearchExportData from '@/components/modules/smartforms/export/Search'
import ExportData from '@/components/modules/smartforms/export/ExportData'
import StudentTags from "@/components/modules/manage/students/StudentTags"
import UserTags from "@/components/modules/manage/users/UserTags"
import ProgramEvaluation from '@/components/modules/programevaluation/ProgramEvaluation'
import HistoricalScores from "@/components/modules/studentprofile/HistoricalScores"
import Charting from '@/components/modules/summary/Charting'
import SaveSearchContainer from '@/components/modules/analytics/SaveSearchContainer'
import UsersMerge from '@/components/modules/manage/users/UsersMerge'
import StudentsMerge from '@/components/modules/manage/students/StudentsMerge'
import IncidentCreation from '@/components/modules/incidents/entry/index'

export default {
    name: 'app',
    components: {
        WindowHandler,
        ApplicationTimeout,
        Calendar,
        NavigationBar,
        AssessmentTrendline,
        BigFive,
        RiskRatio,
        SmartForm,
        BenchmarkChart,
        InterventionCreator,
        UserGuides,
        SmartFormTemplate,
        ValidateUpload,
        UploadDetail,
        ScoreEditor,
        AssessmentHistory,
        IFrameRenderer,
        EditActionItem,
        AcademicProgressMonitoring,
        InterventionWorkspace,
        InterventionParticipation,
        AttendanceReporting,
        EnrollmentReporting,
        StudentAttendance,
        PeriodCounts,
        AttendanceChart,
        IncidentOverview,
        IncidentCharting,
        BigFiveChart,
        TagStudent,
        OpenIncidents,
        MyIncidents,
        InterventionDrilldown,
        StudentMonitorScores,
        BehaviorProgressMonitoring,
        CreateScatterPlot,
        ScatterPlot,
        EndingInterventions,
        ChartDrilldown,
        MeetingCreation,
        PointSheetMonitoring,
        DataWall,
        StackedChart,
        AcademicProgressMonitoring2,
        FormReport,
        Slo,
        SloCreationDatawall,
        SloCreationCharting,
        ObservationCreator,
        StandardObservation,
        SearchExportData,
        ExportData,
        StudentTags,
        UserTags,
        ProgramEvaluation,
        HistoricalScores,
        Charting,
        SaveSearchContainer,
        UsersMerge,
        StudentsMerge,
        IncidentCreation,
    },
    watch: {
        // If user performs an action that directs them to a separate part of the
        // application (e.g. clicks "View Student Profile" from within Students3D),
        // the sidebar's "active" button should update to match
        // if can't find item, then clear button (e.g. on launchpad)
        '$route.name'(v) {
            let item = this.items.find(itm => itm.routeName == v || itm.route == '/' + v)
            if (item) {
                this.activeBtn = {
                    name: item.id,
                    type: item.type,
                }
            } else {
                this.clearActiveBtn()
            }
        },
        kpiDashboardCount(v) {
            if (v >= this.shareableStores.kpi_dashboard_count) {
                let manageAD = this.$hasSecurity('MANAGE_ACHIEVEMENT_DASHBOARD') || this.$hasSecurity('ACCESS_ACHIEVEMENT_DASHBOARD')
                this.adEnabled = manageAD || v > 0 ? true : false
            }
        },
    },
    computed: {
        exportFormDataEnabled () { return this.$store.getters['flags/flags']['ec-export-forms-data'] === true },
        ...mapState('global', [
            'currentModule', 'loading', 'dockableWindows','hideNav', 'restrictedUI',
            'sessionUser', 'studentCard', 'studentsToTag','shareableStores', 'fbMonitorAvailable', 'fbMonitorManage', 'kpiDashboardCount',
        ]),
        fbMonitorAvailable () { return this.$store.state.global.fbMonitorAvailable },
        fbMonitorManage () { return this.$store.state.global.fbMonitorManage },
        kpiDashboardCount() { return this.$store.state.global.kpiDashboardCount },
        analyticsEnabled() {
            return this.$store.getters['flags/flags']['ec-analytics'] === true
        },
        dashboardOnly() {
            return this.sessionUser.district.dashboard_only ? true : false
        },
        adSpark() {
            return this.sessionUser.district.dashboard_spark ? true : false
        },
        visibleWindows() {
            return this.dockableWindows
        },
        minimizedWindows() {
            return this.dockableWindows.filter(r => {
                return r.docked
            })
        },
        leftNavHeight() {
            let nav = document.getElementById('left-nav-main')
            if (nav) {
                return 1000
            }
            return 500
        },
        items() {
            let me = this
            let restrict = this.restrictedUI ? true : false

            return [{
                id: 'plus', type: 'slider', icon: 'far fa-plus',
                disabled: restrict ,
                items: [{
                    id: 'create-task',
                    name: 'Create Action Item',
                    handler() {
                        me.actionItemDialog = true
                    }
                }, {
                    id: 'create-intervention',
                    name: 'Create Intervention',
                    handler() {
                        // if there are FB monitors and they're accessible, show dialog.
                        // If not, start create intervention process.
                        if (me.fbMonitorAvailable) {
                            me.interventionDialog.show = true
                        } else {
                            me.createIntervention()
                        }
                    }
                }, {
                    id: 'create-incident',
                    name: 'Create Incident',
                    handler() {
                        if(me.useNewIncidentCreation) {
                            me.$store.commit('global/addDockableWindow', {
                                name: 'Create Incident',
                                component: 'incident-creation',
                                attrs: {}
                            })
                        } else {
                            me.$store.commit('global/addDockableWindow', {
                                name: 'Create Incident',
                                component: 'i-frame-renderer',
                                attrs: {
                                    app: 'incident_creation',
                                    cfg: {
                                    }
                                }
                            })
                        }
                    }
                }, {
                    id: 'create-meeting',
                    name: 'Create Meeting',
                    handler() {
                        me.meetingsDialog = {
                            show: true,
                            created: null,
                            events: {
                                close() {
                                    me.meetingsDialog.show = false
                                    me.$store.commit('global/reloadLaunchpad', { })
                                },
                                closed() {
                                    me.meetingsDialog.created = null
                                    me.meetingsDialog.show = false
                                    me.$store.commit('global/reloadLaunchpad', { })
                                },
                                created(rec) {
                                    me.meetingsDialog.created = rec
                                    me.meetingsDialog.show = false
                                },
                            },
                        }
                    }
                }, {
                    id: 'enter-scores',
                    name: 'Enter Assessment Scores',
                    handler() {
                        me.$store.commit('global/addDockableWindow', {
                            name: 'Enter Assessment Scores',
                            component: 'score-editor',
                            attrs: { windowId: this.selectedWindowId }
                        })
                    }
                }, {
                    id: 'create-scatter-plot',
                    name: 'Create Scatter Plot',
                    handler() {
                        let p = me.$showPanel({
                            component: CreateScatterPlot,
                            width: 400,
                            openOn: 'right',
                            disableBgClick: true,
                            cssClass: 'main-left-slideout',
                            props: {
                                class: 'ma-5'
                            }
                        })

                        p.promise.then(res => {})
                    }
                }, {
                    id: 'create-data-export',
                    name: 'Export Data',
                    disabled: me.exportFormDataEnabled && me.$hasSecurity('EXPORT_FORMS_DATA') ? false : true,
                    handler() {
                        me.searchExport = {
                            show: true,
                            events: {
                                close() {
                                    me.searchExport.show = false
                                },
                                accept(v) {
                                    me.searchExport.show = false
                                    me.exportData.show = true
                                    me.exportData.rec = v
                                },
                            }
                        }
                    }
                }]
            },
            // { id: 'star', type: 'slider', icon: 'far fa-star', disabled: restrict },
            { id: 'divider1', type: 'divider'},
            // { id: 'charting', type: 'icon', icon: 'far fa-analytics', name: 'Charting', disabled: restrict },
            { id: 'info', type: 'icon', icon: 'far fa-address-card', name: 'Student Profile', route: '/StudentProfile', routeName: 'StudentProfile', disabled: restrict },
            { id: 's3d', type: 'icon', icon: 's3d', name: 'Students 3D', route: '/Students3D', routeName: 'Students3D' },
            { id: 'ad', type: 'icon', icon: 'fas fa-tv-alt', name: 'Achievement Dashboard', stacked: 'AD', route: '/AchievementDashboard', routeName: 'AchievementDashboard',
                disabled: restrict,
                hidden: (!me.shareableStores.district.dashboard_enabled && !me.shareableStores.district.dashboard_spark && !me.shareableStores.district.dashboard_only) || !me.adEnabled
            },
            { id: 'analytics', type: 'icon', icon: 'far fa-analytics', name: 'Charting', route: '/Analytics', routeName: 'Analytics', disabled: restrict, hidden: !me.analyticsEnabled },
            { id: 'collections', type: 'icon', icon: 'far fa-puzzle-piece', name: 'Collections', route: '/Collections', routeName: 'Collections', disabled: restrict },
            { id: 'divider2', type: 'divider'},
            { id: 'meetings', type: 'icon', icon: 'far fa-calendar', name: 'Meetings', stacked: 'fas fa-user', route: '/Meetings', routeName: 'MeetingWorkspace', disabled: restrict },
            { id: 'forms', type: 'icon', icon: 'far fa-clipboard', name: 'smartFORMS', route: '/SmartForms', routeName: 'smartFORM', stacked: 'fas fa-sparkles' },
            { id: 'incident', type: 'icon', icon: 'far fa-whistle', name: 'Incidents', route: '/Incidents', routeName: 'Incidents' },
            { id: 'interventions', type: 'icon', icon: 'far fa-hands-heart', name: 'Interventions', disabled: restrict, route: '/Interventions', routeName: 'Interventions' },
            { id: 'thresholds', type: 'icon', icon: 'far fa-pennant', name: 'Thresholds', route: '/Thresholds', routeName: 'Thresholds', hidden: (!me.$hasSecurity('MANAGE_THRESHOLDS') && !me.$hasSecurity('VIEW_THRESHOLDS'))},
            { id: 'actions', type: 'icon', icon: 'far fa-tasks', name: 'Action Items', route: '/ActionItems', routeName: 'ActionItems', disabled: restrict },
            { id: 'spacer1', type: 'spacer', disabled: restrict },
            { id: 'divider3', type: 'divider'},
            { id: 'uploads', type: 'icon', icon: 'far fa-cloud-upload', name: 'Uploads', route: '/Uploads', routeName: 'Uploads', hidden: !me.$hasSecurity('UPLOAD_DATA') },
            { id: 'configs', type: 'icon', icon: 'fas fa-cog', name: 'System Configuration', route: '/Manage', routeName: 'Manage'}
            ]
        },
        useNewIncidentCreation() { return this.$store.getters['flags/flags']['ec-incident-repo-move'] === true }
    },

    created() {
        // AD only clients?
        if (this.dashboardOnly) {
            if (!this.$isSupport()) {
                let valid = ['ad', 'uploads', 'configs', 'actions']
                this.items.forEach(item => {
                    if (valid.indexOf(item.id) === -1 && item.id != 'spacer1' && item.id != 'divider3') {
                        item.disabled = true
                        item.hidden = true
                    }
                })
            }
            if (!this.adSpark) this.$router.push('/AchievementDashboard')
        }

        if(this.$route.query.hideNav) {
            this.$store.commit('set', {
                module: 'global',
                state: 'hideNav',
                value: this.$route.query.hideNav
            })
        }

        if(this.$route.query.restrictedUI) {
            this.$store.commit('set', {
                module: 'global',
                state: 'restrictedUI',
                value: this.$route.query.restrictedUI
            })
        }

    },

    mounted() {
        if(this.shareableStores.authx_enabled && this.shareableStores.authx_provider) this.injectIESessionIframe()

        this.resize()

        this.showDistrictMessage()

        let nav = this.items.find((item) => item.route == this.$route.path)
        if (nav) this.navigation(nav)

        window.onpopstate = function () {
            if(this.$router) this.$router.go(-1)
        }

        // check for any FB plans (show management page)
        this.$modelGet('fastBridgePmPlans', {property: 'plans'})
            .then(fb => {
                if (fb.plans?.length > 0) {
                    this.$store.commit('global/setFbMonitorManage', {value: true})
                }
            })

        // check for available FB plans (show intervention creation dialog)
        this.$modelGet('fastBridgePmPlans', {property: 'available'})
            .then(fb => {
                if (fb.available?.length > 0) {
                    this.$store.commit('global/setFbMonitorAvailable', {value: true})
                }
            })

        let manageAD = this.$hasSecurity('MANAGE_ACHIEVEMENT_DASHBOARD') || this.$hasSecurity('ACCESS_ACHIEVEMENT_DASHBOARD')
        this.adEnabled = manageAD || this.shareableStores?.kpi_dashboard_count > 0 ? true : false
    },
    methods: {
        createIntervention(fb=false) {
            let p = this.$showPanel({
                component: InterventionCreator,
                width: 500,
                openOn: 'right',
                disableBgClick: true,
                cssClass: 'main-left-slideout',
                props: {
                    class: 'pa-5',
                    fbMonitorAvailable: fb, // false = use eC, true = use FB
                    fbMonitorId: this.fbpm.id, // FB monitor id
                    fbMonitorName: this.fbpm.intervention_name, // FB monitor name of selected
                    fbMonitorStudents: this.fbpm.students, // Students in selected FB monitor
                    fbMonitorStartDate: this.fbpm.start_date, // FB monitor start date
                    fbMonitorEndDate: this.fbpm.end_date, // FB monitor end date
                    fbMonitorType: this.fbpm.sub_category_id, // FB monitor sub category
                    fbMonitorSkill: this.fbpm.category_id, // Assessment category (skill)
                    fbMonitorUnmatched: this.fbpm.unmatched_student_count, // Unmatched student count

                }
            })

            p.promise.then(res => {
                // reset dialog
                this.loadFbPlans()
            })
        },
        injectIESessionIframe() {
            let sessionIframe = document.getElementById('ie_session_iframe')
            if (sessionIframe) return

            let iframe = document.createElement('iframe');
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('id', 'ie_session_iframe');
            iframe.setAttribute('height', '0');
            iframe.setAttribute('width', '0');
            iframe.setAttribute('src', this.shareableStores.authx_session);
            document.body.appendChild(iframe);
            return
        },
        topNavClick(r) {
            if (r.id === 'calendar') {
                this.$store.commit('global/addDockableWindow', {
                    name: 'Calendar',
                    component: 'calendar',
                    attrs: {
                        id: 433
                    }
                })
            }
        },
        showDistrictMessage() {
            let show = true
            if (process.env.NODE_ENV === 'development') show = false
            if (this.hideNav) show = false
            if (this.$_.isEmpty(this.sessionUser.district.login_message)) show = false
            this.districtMessage.show = show
        },
        handleMenuClick(opt) {
            if (opt.handler) {
                opt.handler()
                return
            }
            this.createDialog.component = opt.component
            this.createDialog.title = opt.name
            this.createDialog.attrs = opt.attrs ? opt.attrs : false
            this.createDialog.show = true
        },
        toggleWindow(docked, dockedWindowId) {
            let arr = [...this.dockableWindows]
            let r = arr.find(rec => { return rec.dockedWindowId === dockedWindowId })

            if (r) {
                r.docked = docked
                if (r.events && r.events.toggle) r.events.toggle(!docked)
            }

            this.$store.commit('set', {
                module: 'global',
                state: 'dockableWindows',
                value: arr
            })
        },
        snapWindow(obj, position) {
            let id = obj.dockedWindowId
            this.$store.commit('global/updateDockableWindow', {
                id: id,
                obj: { snap: position }
            })
            if (obj.component === 'highcharts') {
                let ref = `dockable-window-${id}-component`
                this.$nextTick(() => this.$refs[ref][0]?.chart?.reflow())
            }
        },
        resize() {
            let mc = this.$refs.mainContainer
            mc.style.height = String(window.innerHeight - mc.getBoundingClientRect().y) + 'px'
        },
        goHome() {
            this.$setCurrentModule(null, '/')
        },
        removeDockedWindow(comp, dockedWindowId) {
            if (comp.events && comp.events.close) {
                comp.events.close()
            }

            this.$store.commit('global/removeDockedWindow', dockedWindowId)
        },
        onDismissActionItemDialog() {
            this.actionItemDialog = false;
            this.$store.commit('global/reloadLaunchpad', { })
        },
        navigation(item) {
            let enabled = ['thresholds', 'ad', 's3d', 'configs', 'uploads', 'forms', 'incident']

            if (this.restrictedUI && enabled.indexOf(item.id) === -1) {
                this.$snackbars.$emit('new', { text: 'This feature is not yet enabled', usage: 'warning' })
                return
            }
            //if clicking the same slider button twice
            if (this.activeBtn && (this.activeBtn.name === 'plus' || this.activeBtn.name === 'star')) {
                this.showSlider = true
                this.activeBtn = null
            } else {
                this.activeBtn = {
                    name: item.id,
                    type: item.type
                }
                this.activeBtn.name === 'plus' || this.activeBtn.name === 'star' ? this.showSlider = true : this.showSlider = false
            }

            if (item.route) {
                this.$router.push(item.route)
            }
        },
        clearActiveBtn() {
            this.activeBtn =  null
        },
        loadFbPlans() {
            // defaults
            this.fbPlans = []
            this.fbpm.id = -1
            this.fbPlans.push(
                {id: -1, intervention_name: 'Select an option'},
                {id: 0, intervention_name: 'A setup hasn’t been created yet.'}
            )

            // FastBridge Progress Monitors
            // If FB PMs are available, give users the option to create an Intervention using an FB PM
            this.$modelGet('fastBridgePmPlans', {property: 'available'})
                .then(fb => {
                    if (fb.available.length > 0) {
                        this.$store.commit('global/setFbMonitorAvailable', {value: true})
                        fb.available.forEach(plan => {
                            this.fbPlans.push(plan)
                        })
                    }
                })
        },
        loadingNP(loading) {
            this.npLoading = loading
        },
    },

    data() {
        return {
            districtMessage: {
                show: false
            },
            showSlider: false,
            plus: null,
            star: null,
            collections: null,
            s3d: null,
            ad: null,
            charting: null,
            info: null,
            actions: null,
            meetings: null,
            incidents: null,
            interventions: null,
            forms: null,
            thresholds: null,
            uploads: null,
            configs: null,
            plusActive: false,
            selectedIcon: null,
            activeBtn: null,
            createDialog: {
                show: false,
                component: '',
                attrs: {},
                width: 500
            },
            interventionDialog: {
                show: null,
                includefb: false,
            },
            meetingsDialog: {
                show: null,
                created: null,
                events: {},
            },
            actionItemDialog: false,
            searchExport: {
                show: false,
                events: {},
            },
            exportData: {
                show: false,
                rec: {},
            },
            fbpm: {},
            fbPlans: [],
            npLoading: false,
            adEnabled: null,
        }
    }
}
</script>

<style lang="scss">
@import "./style/educlimber.scss";

.fe-window {
    ::v-deep .v-menu__content {
        z-index: 1000 !important;
    }
}

// I could not get headling to switch anyhere but here.
.ec-fe-lib .v-application .headline {
    font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
}


.page-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.flex-fill {
    // .d-flex
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    // .flex-grow-1
    -webkit-box-flex: 1!important;
    -ms-flex-positive: 1!important;
    flex-grow: 1!important;
    // .align-content-stretch
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important;
    // force full height
    height: 100%;
}
.no-scroll {
    overflow: hidden;
}
.v-input__slot {
    padding: 0 8px !important;
}

.fe-grid-row-changed {
    background-color: #7AA2D3 !important;
}
.fe-grid-row-changed-transition {
    transition: background-color 1s ease-in !important;
}

.fe-grid-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    pointer-events: cursor;
    color: rgba(0,0,0,.54);
}

.fe-grid-cell-disabled {
    opacity: .38;
    pointer-events: none;
}

.ec-simple-tip {
    position: relative;
}
.ec-simple-tip:hover::after {
    position: absolute;
    float: right;
    border-radius: 3px;
    left: calc(100% + 10px);
    opacity: .9;
    z-index: 10000;
    background: black;
    color: white;
    padding: 8px;
    content: attr(data-tip);
    font-size: 12px;
    font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif;
}

.ec-fa-icon-stacked {
    font-size: 7px;
    margin-bottom: 3px;
    &-2x {
        font-size: 15px !important;
    }
    &.fa-inverse {
        color: inherit;
    }
}

.ec-fa-icon-text-stacked {
    color: #353D56;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.5px;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 8px;
    line-height: 13px;
    justify-content: center;
    padding-left: 3px;
}

.ec-fa-icon-text-bold {
    font-family: CerebriSans-Bold, Cerebri Sans, Roboto, sans-serif;
}

.ec-left-nav-main {
    .v-btn {
        &:hover span {
            i, span {
                color: #fff !important;
            }
        }
    }
}

.selected-icon {
    .ec-fa-icon-stacked, .ec-fa-icon-text-stacked{
        color: #fff !important;
    }
}

.ec-page-title {
    font-size: 22px;
    font-weight: 600;
}
.ec-title {
    font-size: 18px;
    font-weight: 600;
}
.ec-section-title {
    font-size: 16px;
    font-weight: 600;
}
.ec-section-subtitle {
    font-size: 14px;
    font-weight: 600;
}
.ec-caption {
    font-size: 12px;
}

.ec-group-nav {
    align-items: center;
    display: flex;
    .ec-group-nav-option {
        border: 1px solid lightgray;
        button.fe-button.v-btn.secondary {
            border: none !important;
            color: black !important;
            margin: 0;
        }
        &.title-bar button.fe-button.v-btn.secondary {
            max-height: 28px;
            width: 120px;
        }
        &.selected {
            background-color: var(--fe-selected);
            pointer-events: none;
        }
        &.first, button.fe-button.v-btn.secondary {
            border-radius: 4px 0px 0px 4px;
        }
        &.middle, button.fe-button.v-btn.secondary {
            border-radius: 0;
        }
        &.last, button.fe-button.v-btn.secondary {
            border-radius: 0px 4px 4px 0px;
        }
    }
}

</style>

<style lang="scss" scoped>
.minimize-count {
    position: absolute;
    right: 10px;
    top: 0px;
}

.main-container {
    // outline: 2px solid rgba(0,0,255,.8);
    // outline-offset: -2px;
    padding: 0px;
}

.selected-icon {
    background: var(--v-primary-base) !important;
    color: #fff !important;
}

.left-nav-main {
    padding: 8px;
    overflow: visible;
    border-left: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-right: 1px solid rgba(0, 0, 0, .12);
    background: white !important;
    ::v-deep .v-navigation-drawer__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: inherit;
    }
    .v-btn {
        margin: 0;
        color: #353D56;
        min-height: 40px;
        min-width: 52px !important;
        max-width: 52px !important;
        border-radius: 5px;
        justify-content: flex-start;
        overflow: hidden;
        ::v-deep .v-btn__content {
            left: -6px;
            .nav-icon {
                width: 32px;
                margin-right: 18px;
            }
            .v-icon {
                font-size: 16px !important;
            }
        }
        &:hover.btn-expand {
            max-width: unset !important;
            min-width: 175px !important;
            z-index: 100;
        }
        &:hover {
            background-color: var(--v-primary-base);
            color: #fff !important;
            strong {
                color: #fff !important;
            }
        }
    }
}

.left-nav-drawer {
    height: 99.5% !important;
    left: 68px;
    width: 300px;
    background: rgba(249, 250, 252, 0.98);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.product-alert {
    margin: auto;
    height: 55px;
    width: 557px;
    padding: 17px 56px;
    border-left: 10px solid #80B6C7;
    background-color: #CCE2E9;
    background-position: 16px 10px;
    background-size: 28px;
    background-image: url(/images/FastBridge_logo.png);
}

.fb-warning {
    margin-top: -7px;
    margin-left: 45px;
}

.fb-warning-text {
    font-size: 12px;
    float: right;
    padding-left: 15px;
    width: 485px;
}

</style>
