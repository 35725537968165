<template>
    <div class="guardian-reporting">
        <a class="mr-5 mt-5" @click="goToSmartForms">
            <i style="color: #006c90;" class="fal fa-chevron-left"></i> Back to smartFORMS</a>
        <h1 class="mt-4">Guardian Emails for {{ templateName }} Results</h1>
        <div class="count-groups-container">
            <span class="count-group mt-5">
                <img src="/images/guardians-emailed.png"/>
                <p class="count-text">
                    <b class="count">{{ totalSent }}</b>
                    <br>
                    Guardians emailed
                </p class="count-text">

                <img class="ml-10" src="/images/guardians-delivered.png"/>
                <p class="count-text">
                    <b class="count">
                        {{ delivered }}
                    </b>
                    <br>
                    <span class="count-info">
                        Delivered
                        <fe-tooltip
                            tooltip="The email with the linked smartFORM(s) has been delivered to a guardian’s email inbox. This does not indicate whether the email  has been read or seen yet."
                        >
                            <i class="fas fa-info-circle"></i>
                        </fe-tooltip>
                    </span>
                </p class="count-text">

                <img class="ml-10" src="/images/guardians-undelivered.png"/>
                <p class="count-text">
                    <b class="count">{{ undeliverable }}</b>
                    <br>
                    <span class="count-info">
                        Undeliverable
                        <fe-tooltip
                            tooltip="The email was not successfully delivered. You may be able to resend the smartFORM(s) to these guardians. You may need to take care of any blocked or invalid email addresses."
                        >
                            <i class="fas fa-info-circle"></i>
                        </fe-tooltip>
                    </span>
                </p class="count-text">
            </span>

            <span class="count-group mt-5" v-if="responseRequired">
                <img src="/images/guardians-responded.png"/>
                <p class="count-text">
                    <b class="count">{{ responseReceived }}</b>
                    <br>
                    <span class="count-info">
                        Responded
                        <fe-tooltip
                            tooltip="These guardians have  acknowledged reviewing  the smartFORM(s) they received."
                        >
                            <i class="fas fa-info-circle"></i>
                        </fe-tooltip>
                    </span>
                </p class="count-text">


                <img class="ml-10" src="/images/guardians-no-response.png"/>
                <p class="count-text">
                    <b class="count">{{ noResponse }}</b>
                    <br>
                    <span class="count-info">
                        No Response
                        <fe-tooltip
                            tooltip="These guardians have received the email with the linked smartFORM(s) but have not acknowledged reviewing them."
                        >
                            <i class="fas fa-info-circle"></i>
                        </fe-tooltip>
                    </span>
                </p class="count-text">
            </span>
        </div>

        <v-tabs height="48px" v-model="activeTab" class="ec-collection-tabs mt-4 mb-4">
            <v-tab class="small-tab small-tab-all" data-test="guardians-tab">Guardians</v-tab>
            <v-tab class="small-tab small-tab-all" data-test="undelivered-tab">Undeliverable</v-tab>
            <v-tab class="small-tab small-tab-all" data-test="responded-tab">Responded</v-tab>
            <v-tab class="small-tab small-tab-all" data-test="no-response-tab">No Response</v-tab>
        </v-tabs>

        <div v-if="activeTab === 1">
            <p class="mt-6">The following guardian emails were not successfully delivered. You can try to resend emails that bounced due to a temporary error. Emails that are blocked cannot be resent. Update guardian contact information in your Student Information System (SIS) or through a Student Guardian file upload.</p>
            <div v-if="guardianReportingV3Display" data-test="no-response-filter-checkbox">
                <v-checkbox v-model="showOnlyResendable" @change="fetchData" class="mt-6" style="margin-left: -10px;">
                    <template v-slot:label>
                        <span class="checkbox-label">Show only emails that can be resent </span>
                    </template>
                </v-checkbox>
            </div>
        </div>

        <p v-else-if="activeTab === 2" class="mt-6">The following guardians have acknowledged they have reviewed each form. This means they were able to access the shared form and indicated that they reviewed the form.</p>

        <div v-else-if="activeTab === 3">
            <p class="mt-6">The following guardians received the email but have not acknowledged they have reviewed each form. If the link is no longer valid, you will need to resend the form.</p>
            <div v-if="guardianReportingV3Display" data-test="resendable-filter-checkbox">
                <v-checkbox v-model="excludeStudentsWithResponse" @change="fetchData" class="mt-6" style="margin-left: -10px;">
                    <template v-slot:label>
                        <span class="checkbox-label">Exclude students with at least one guardian response</span>
                    </template>
                </v-checkbox>
            </div>
        </div>


        <div class="no-sent-data" v-if="apiFailed">
            <img src="/images/guardian-sent-api-failed.png"/>
            <h5>No data available</h5>
            <p>We are experiencing technical difficulties. Refresh the page or try again later.</p>
            <fe-btn
                data-test="refresh-btn"
                usage="primary"
                @click="refresh"
            >
                Refresh
            </fe-btn>
        </div>
        <div class="no-sent-data" v-else-if="!rowData.length">
            <img src="/images/no-guardians.png"/>
            <h5>No guardian emails sent</h5>
            <p>There were no guardian emails sent during this time period.</p>
        </div>
        <fe-grid
            class="guardian-reporting-grid"
            v-else
            :showToolbar="true"
            style="height: 500px;"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :showAddRowBtn="false"
        />
    </div>
</template>

<script>
export default {
    name: 'GuardianEmailReporting',
    data() {
        return {
            activeTab: 0,
            templateId: '',
            templateName: '',
            totalSent: 0,
            delivered: 0,
            undeliverable: 0,
            responseReceived: 0,
            responseRequired: 0,
            noResponse: 0,
            rowData: [],
            apiFailed: false,
            excludeStudentsWithResponse: false,
            showOnlyResendable: false,
        }
    },
    computed: {
        columnDefs() {
            const columnDefs = [
                { headerName: 'Student', field: 'studentName', flex: 1, sortable: true },
                { headerName: 'Guardian Email', field: 'guardianEmail', flex: 1, sortable: true },
                { headerName: 'Form Name', field: 'formName', flex: 1, sortable: true },
                { headerName: 'School', field: 'schoolName', flex: 2, sortable: true },
                { headerName: 'Sent By', field: 'sentBy', flex: 1, sortable: true, hide: true },
                { headerName: 'Email Sent', field: 'sentDate', flex: 1, sortable: true, valueFormatter: v => v.data.sentDate ? this.$dayjs(v.data.sentDate).format('M/D/YY') : 'Sending' }
            ]

            switch (this.activeTab) {
                case 1:
                    columnDefs.push({
                        headerName: 'Error Details',
                        field: 'errorDetails',
                        flex: 1,
                        sortable: true,
                        cellRenderer: this.errorDetailsCellRenderer,
                    })
                    break
                case 2:
                    columnDefs.push(
                        { headerName: 'Responded', field: 'respondedDate', flex: 1, sortable: true, valueFormatter: v => v.data.respondedDate ? this.$dayjs(v.data.respondedDate).format('M/D/YY') : 'No response' },
                    )
                    break
                default:
                    columnDefs.push(
                        { headerName: 'Responded', field: 'respondedDate', flex: 1, sortable: true, valueFormatter: v => v.data.respondedDate ? this.$dayjs(v.data.respondedDate).format('M/D/YY') : 'No response' },
                        { headerName: 'Link Valid Until', field: 'expirationDate', flex: 1, sortable: true, valueFormatter: v => this.$dayjs(v.data.expirationDate).format('M/D/YY') }
                    )
                    break
            }

            return columnDefs
        },
        guardianReportingV3Display() {
            return this.$store.getters['flags/flags']['e-c-guardian-notifications-v-3-filters-and-resends']
        },
    },
    methods: {
        fetchData() {
            let apiEndpoint
            switch (this.activeTab) {
                case 1:
                    apiEndpoint = 'guardianFormReportingUndeliverable'
                    break
                case 2:
                    apiEndpoint = 'guardianFormReportingResponded'
                    break
                case 3:
                    apiEndpoint = 'guardianFormReportingNoResponse'
                    break
                default:
                    apiEndpoint = 'guardianFormReporting'
            }

            const params = { templateIds: this.templateId }
            if (this.excludeStudentsWithResponse) {
                params.anyResponse = true
            }
            
            if (this.showOnlyResendable) {
                params.resendable = true
            }

            this.$modelGet(apiEndpoint, params)
                .then(response => {
                    if (this.activeTab === 1) {
                        this.rowData = response.sent_list.map(item => ({
                            ...item,
                            errorDetails: this.getErrorDetails(item)
                        }));
                    } else {
                        this.rowData = response.sent_list;
                    }
                })
                .catch(err => {
                    console.warn(`Unable to load data from ${apiEndpoint}`, err)
                    this.apiFailed = true
                })
        },
        refresh() {
            this.apiFailed = false
            this.fetchData()
        },
        updateDataFromRoute(route) {
            this.templateId = route.query.templateId
            this.templateName = route.query.templateName
            this.totalSent = Number(route.query.totalSent)
            this.delivered = Number(route.query.delivered)
            this.undeliverable = Number(route.query.undeliverable)
            this.responseReceived = Number(route.query.responseReceived)
            this.responseRequired = Number(route.query.responseRequired)
            this.noResponse = this.responseRequired - this.responseReceived
            this.activeTab = Number(route.query.activeTab) || 0
            this.fetchData()
        },
        getErrorDetails(item) {
            //Priority order is impportant because multiple scenarios can be true for an email
            const conditions = [
                { condition: item.blocked, message: 'Blocked' },
                { condition: item.bounced, message: 'Bounced' },
                { condition: item.sentDate && !item.deliveredDate, message: 'Unable to send' }
            ]

            for (const { condition, message } of conditions) {
                if (condition) {
                    return message
                }
            }

            return 'Unable to send'
        },
        errorDetailsCellRenderer(params) {
            const errorDetails = params.data.errorDetails
            let tooltipText = ''
            let icon = ''

            switch (errorDetails) {
                case 'Blocked':
                    tooltipText = 'An address is blocked when the recipient rejected the email. This can happen for a variety of reasons, such as an invalid email or the email is flagged as spam. You will need to use a different email address for this guardian. Update your records before resending.'
                    icon = 'ban'
                    break
                case 'Bounced':
                    tooltipText = 'An email bounces when there is a temporary issue. For example, the recipient’s email server may have been down when the email was sent. You can resend emails to try to resolve this issue.'
                    icon = 'exclamation-circle'
                    break
                case 'Unable to send':
                    tooltipText = 'This form was not sent due to a temporary system error. You can resend this email to try to resolve this issue.'
                    icon = 'exclamation-circle'
                    break
                default:
                    tooltipText = ''
                    icon = ''
            }

            return `<div style="color: #BF0D00;" title="${tooltipText}"><i class="fal fa-${icon}"></i> ${errorDetails}</div>`
        },
        goToSmartForms() {
            this.$router.push({
                path: '/smartFORMS',
                query: { activeTab: 5 }
            })
        }
    },
    watch: {
        activeTab() {
            this.fetchData()
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.updateDataFromRoute(to)
        })
    },
    beforeRouteUpdate(to, from, next) {
        this.updateDataFromRoute(to)
        next()
    },
}
</script>

<style scoped>
.guardian-reporting {
    position: relative;
    margin: 8px;
    padding: 20px;
}

.filter-btn {
    margin-left: -12px;
    margin-top: 20px;
}

.count-group {
    display: flex;
    align-items: center;
    padding: 16px;
    border: 1px solid #E0E1E6;
    border-radius: 5px;
    height: 94px;
}

.count-text {
    margin-left: 12px;
    margin-top: 18px;
}

.count-groups-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.no-sent-data {
    margin-top: 20px;
    border: 1px solid #E0E1E6;
    border-radius: 5px;
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.no-sent-data h5 {
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 4px;
}

.count-info {
    display: flex;
    align-items: center;
}

.count-info i {
    margin-left: 4px;
    color: #495B8F;
}

.guardian-reporting-grid {
    height: 100%;
}

.guardian-reporting-grid ::v-deep .ag-header-cell, 
.guardian-reporting-grid ::v-deep .ag-cell {
    border-right: none !important;
}

.count {
    font-size: 20px;
    font-weight: bold;
}

.checkbox-label {
    font-size: 14px;
}
</style>