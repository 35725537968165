import { render, staticRenderFns } from "./MealStatusPanel.vue?vue&type=template&id=98727bbc&scoped=true"
import script from "./MealStatusPanel.vue?vue&type=script&lang=js"
export * from "./MealStatusPanel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98727bbc",
  null
  
)

export default component.exports