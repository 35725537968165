<template>
<div>
    <button @click="dialog=true" >{{displayName}}</button>
    <v-dialog v-model="dialog" width="600" :persistent="persistDialog">
        <fe-stepper-filter
            :title="title" style="height:600px;"
            @apply="emit" @close="dialog=false"
            :applyDisabled="!formValid || !enableFinalPanel"
        >

            <fe-stepper-checkbox-panel v-model="panelValues.tags" :items="tagItems" width="50%">

                <template v-slot:label="{item}">
                    <v-icon :color="item.color" class="mx-2">fa-tag</v-icon>
                    {{item.name}}
                </template>

            </fe-stepper-checkbox-panel>
            <v-divider vertical style="background-color:#E0E1E6"/>

            <fe-stepper-panel width="50%" style="padding:10px;">
                <v-form v-if="enableFinalPanel" lazy-validation v-model="formValid">
                    <FeDatePicker label="Tagged Before" v-model="panelValues.tagged_as_of_date" />
                    <fe-remote-combo
                        label="School Year"
                        v-model="panelValues.school_year"
                        rootProperty="years"
                        url="qry.php?action=get&property=school_years"
                    ></fe-remote-combo>
                    <v-layout row>
                        <v-flex class="pr-2">
                            <v-switch v-model="panelValues.restrict" label="Restrict Results" hide-details />
                        </v-flex>
                        <v-flex>
                            <v-switch v-model="panelValues.require_all" label="Require All" hide-details />
                        </v-flex>
                    </v-layout>
                </v-form>
            </fe-stepper-panel>

            <template #footer>
                <div class="text-truncate" style="padding:14px">{{breadcrumb}}</div>
            </template>
        </fe-stepper-filter>
    </v-dialog>
</div>
</template>

<script>
import * as Util from '../../Util'
import FilterUtil from '../../FilterPanelUtil'

export default {
    name: 'TagPanel',

    rank: 1,

    data() {
        return {
            formValid: true,
            oldFilter: null,
            displayName: 'Tag',
            type: 'tag',
            dialog: false,
            tagItems: [],
            panelValues: {
                restrict: true,
                tags: [],
                tagged_as_of_date: null,
                require_all: false,
                school_year: null,
            },
            panelOpenState: {},
            persistDialog: false,
        }
    },

    computed: {
        title() {
            return this.oldFilter ? `Edit ${this.displayName} Filter` : `Create ${this.displayName} Filter`
        },
        enableFinalPanel() {
            return !this.$_.isEmpty(this.panelValues.tags)
        },
        breadcrumb() {
            return this.panelValues.tags.map(x=>x.name)
                .filter(Boolean)
                .join(', ')
        },
        computedPanelValues() {
            return Object.assign({}, this.panelValues)
        }
    },

    watch: {
        dialog(v) {
            if(!v) this.oldFilter = null, this.clear()
            if (v) this.loadTags(), this.panelOpenState = this.$_.clone(this.panelValues)
        },
        computedPanelValues: {
            handler(newValue) {
                if (Object.keys(this.panelOpenState).length !== 0) {
                    let objectsAreEqual = JSON.stringify(FilterUtil.sortObject(newValue)) === JSON.stringify(FilterUtil.sortObject(this.panelOpenState))
                    this.persistDialog = !objectsAreEqual
                }
            }
        }
    },

    methods: {
        clear() {
            this.panelValues = {
                restrict: true,
                tags: [],
                tagged_as_of_date: null,
                require_all: false,
                school_year: null,
            }
        },
        load(oldFilter) {
            this.oldFilter = oldFilter
            this.dialog = true
            let ep = oldFilter.editorParams

            this.panelValues = {
                restrict: ep.restrict,
                tags: ep.tags,
                tagged_as_of_date: ep.tagged_as_of_date,
                require_all: ep.require_all,
                school_year: ep.school_year
            }
        },
        emit() {
            this.dialog = false
            let uniqueId = Util.UUIDv4()
            let name = this.panelValues.tags.map(x => x.name).join(', ')
            let filter = {
                type: this.type,
                id: uniqueId,
                restrict: this.panelValues.restrict,
                report: true,
                params: this.$_.pickBy({
                    tag_id: this.panelValues.tags.map(x => x.id),
                    tagged_as_of_date: this.panelValues.tagged_as_of_date,
                    require_all: this.panelValues.require_all,
                    school_year_id: this.panelValues.school_year?.id,
                }, x => !this.$_.isBlank(x)),
            }
            Object.defineProperties(filter, {
                editor: {value: this},
                editorParams: {value: {
                    restrict: this.panelValues.restrict,
                    tags: this.panelValues.tags ? [...this.panelValues.tags] : null,
                    tagged_as_of_date: this.panelValues.tagged_as_of_date,
                    require_all: this.panelValues.require_all,
                    school_year: this.panelValues.school_year ? {...this.panelValues.school_year} : null,
                }},
                grouper: {value: {
                    id: uniqueId,
                    name: name,
                    type: 'propertyArray',
                    args: {path: `filters.${uniqueId}`, property: 'value'},
                }},
                chipText: {value: name},
                tooltipData: {value: this.$_.pickBy({
                    'Tags': name,
                    'Tagged Before': this.panelValues.tagged_as_of_date,
                    'Restrict': this.panelValues.restrict,
                    'Require All': this.panelValues.require_all,
                    'School Year': this.panelValues.school_year?.name,
                }, x => !this.$_.isBlank(x))}
            })
            this.$emit('input', filter, this.oldFilter)
        },
        loadTags() {
            this.$axios({
                url: 'tags.php?action=get&active=1',
            }).then((response) => {
                if (response && response.data) {
                    this.tagItems = response.data.tags
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
