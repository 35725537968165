<template>
<div>
    <button @click="dialog=true" >{{displayName}}</button>

    <v-dialog v-model="dialog" width="600" :persistent="persistDialog">
        <fe-stepper-filter
            :title="title"
            style="height:600px;"
            :applyDisabled="!enableFinalPanel"
            @apply="emit"
            @close="dialog=false"
        >

            <fe-stepper-checkbox-panel
                v-model="panelValues.disabilities"
                :items="disabilityItems"
                width="50%"
                itemValue="id"
                itemText="name"
                @input="restrictSelection"
            />

            <v-divider vertical style="background-color:#E0E1E6"/>

            <fe-stepper-panel width="50%" style="padding:10px;">
                <template v-if="enableFinalPanel">
                    <fe-remote-combo
                        label="School Year"
                        infoText="If a student was exited at any time during the year, they will be considered exited for the entire year."
                        v-model="panelValues.school_year"
                        rootProperty="years"
                        url="qry.php?action=get&property=school_years"
                    />

                    <v-layout row>
                        <v-flex class="pr-2">
                            <v-switch v-model="panelValues.restrict" label="Restrict Results" hide-details />
                        </v-flex>

                        <v-flex>
                            <v-switch v-model="panelValues.require_all" label="Require All" hide-details />
                        </v-flex>
                    </v-layout>
                </template>
            </fe-stepper-panel>

            <template #footer>
                <div class="text-truncate" style="padding:14px">{{breadcrumb}}</div>
            </template>
        </fe-stepper-filter>
    </v-dialog>
</div>
</template>

<script>
import * as Util from '../../Util'
import FilterUtil from '../../FilterPanelUtil'

export default {
    name: 'DisabilityPanel',

    rank: 1,

    data() {
        return {
            oldFilter: null,
            displayName: 'Disability',
            type: 'disability',
            dialog: false,
            disabilityItems: [],
            tagged_as_of_date: null,
            panelValues: {
                disabilities: [],
                require_all: false,
                school_year: null,
                restrict: true
            },
            select_all_item: { id: -1, code: 'ALLDIS', name: 'Select All'},
            panelOpenState: {},
            persistDialog: false,
        }
    },

    computed: {
        title() {
            return this.oldFilter ? `Edit ${this.displayName} Filter` : `Create ${this.displayName} Filter`
        },
        enableFinalPanel() {
            return !this.$_.isEmpty(this.panelValues.disabilities)
        },
        breadcrumb() {
            return this.panelValues.disabilities
                .filter(x => x.id !== -1)
                .map(x => x.display_name || x.description)
                .filter(Boolean)
                .join(', ')
        },
        computedPanelValues() {
            return Object.assign({}, this.panelValues)
        }
    },

    watch: {
        dialog(v) {
            if(!v) this.oldFilter = null, this.clear()
            if(v) this.loadDisabilities(), this.panelOpenState = this.$_.clone(this.panelValues)

        },
        computedPanelValues: {
            handler(newValue) {
                if (Object.keys(this.panelOpenState).length !== 0) {
                    let objectsAreEqual = JSON.stringify(FilterUtil.sortObject(newValue)) === JSON.stringify(FilterUtil.sortObject(this.panelOpenState))
                    this.persistDialog = !objectsAreEqual
                }
            }
        },
    },

    methods: {
        clear() {
            this.panelValues = {
                restrict: true,
                disabilities: [],
                require_all: false,
                school_year: null
            }
        },

        load(oldFilter) {
            this.oldFilter = oldFilter
            this.dialog = true
            let ep = oldFilter.editorParams
            this.panelValues.restrict = ep.restrict
            this.panelValues.disabilities = ep.disabilities
            if (this.panelValues.disabilities.length === this.disabilityItems.length -1) {
                this.panelValues.disabilities.unshift(this.select_all_item)
            }
            this.panelValues.require_all = ep.require_all
            this.panelValues.school_year = ep.school_year
        },

        emit() {
            this.dialog = false
            let uniqueId = Util.UUIDv4()
            let disabilities = this.panelValues.disabilities.filter(x => x.id !== -1)
            let name = disabilities.map(x => x.display_name || x.description).join(', ')

            // combine ids from singles and groups
            let ids = []
            this.panelValues.disabilities.forEach(m => {
                if (!m.display_name_group) {
                    ids.push(m.id)
                } else {
                    ids = ids.concat(m.display_name_group)
                }
                ids = ids.map(i => parseInt(i))
            })

            let filter = {
                type: this.type,
                id: uniqueId,
                restrict: this.panelValues.restrict,
                report: true,
                params: this.$_.pickBy({
                    student_disability_type_id: ids,
                    require_all: this.panelValues.require_all,
                    school_year_id: this.panelValues.school_year?.id,
                }, x => !this.$_.isBlank(x)),
            }
            Object.defineProperties(filter, {
                editor: {value: this},
                editorParams: {value: {
                    restrict: this.panelValues.restrict,
                    disabilities: disabilities ? [...disabilities] : null,
                    require_all: this.panelValues.require_all,
                    school_year: this.panelValues.school_year ? {...this.panelValues.school_year} : null,
                }},
                grouper: {value: {
                    id: uniqueId,
                    name: name,
                    type: 'propertyArray',
                    args: {path: `filters.${uniqueId}`, property: 'value'},
                }},
                chipText: {value: name},
                tooltipData: {value: this.$_.pickBy({
                    'Disability': name,
                    'Restrict': this.panelValues.restrict,
                    'Require All': this.panelValues.require_all,
                    'School Year': this.panelValues.school_year?.name,
                }, x => !this.$_.isBlank(x))}
            })
            this.$emit('input', filter, this.oldFilter)
        },

        loadDisabilities() {
            this.$axios.get(this.$models.getUrl('studentDisabilityType', 'read'))
                .then((response) => {
                    if (response && response.data) {
                        let data = response.data.student_disability_type
                        let filtered = []
                        // if available, use display_name
                        data.forEach(itm => {
                            if (!itm.display_name_group) {
                                itm.name = itm.display_name || itm.description
                                filtered.push(itm)
                            } else {
                                let o = data.find(x => x.id == itm.display_name_group[0]) // find and return first option within display_name_group; id can be string
                                if (!filtered.find(x => x.display_name === o.display_name )) {
                                    o = {
                                        ...o,
                                        name: o.display_name || o.description // use display_name instead of value
                                    }
                                    filtered.push(o)
                                }
                            }
                        })
                        this.disabilityItems = [this.select_all_item, ...filtered]
                    }
            })
        },

        restrictSelection(allSelections, newSelection) {
            if (newSelection.id === -1) {
                if (this.panelValues.disabilities.length === this.disabilityItems.length - 1) {
                    this.panelValues.disabilities = []
                } else this.panelValues.disabilities = [...this.disabilityItems]
            } else if (allSelections.length !== this.disabilityItems.length) {
                this.panelValues.disabilities = this.panelValues.disabilities.filter(x => x.id !== -1)
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>
