<template>
    <div>
        <h3 style="font-size: 18px; margin-bottom: 4px;">Communication Settings</h3>

        <div>Choose to translate forms and have guardians acknowledge reviewing forms.</div>

        <div>
            <v-checkbox
                ref="responseRequiredCheckbox"
                v-model="localResponseRequired"
                class="mt-12"
                @change="emitResponseRequired"
            >
                <template v-slot:label>
                    <span style="font-size: 14px;">
                        Prompt guardians to acknowledge they have reviewed each form.
                    </span>
                </template>
            </v-checkbox>
        </div>

        <div>
            <v-checkbox
                v-if="guardianReportingV3Display"
                ref="translateFormCheckbox"
                v-model="localTranslateForm"
                @change="emitTranslateForm"
            >
                <template v-slot:label>
                    <span style="font-size: 14px;">
                        Translate the linked smartFORMS into the student’s home language, if other than English.
                    </span>
                </template>
            </v-checkbox>
        </div>

        <fe-btn
            data-test="settings-back-btn"
            style="margin-top: 20px; margin-left: -4px;"
            usage="secondary"
            @click="$emit('goToReview')"
        >
            Back
        </fe-btn>

        <fe-btn
            data-test="settings-continue-btn"
            style="margin-top: 20px; margin-left: -4px;"
            usage="primary"
            @click="$emit('goToPreview')"
        >
            Continue
        </fe-btn>
    </div>
</template>
<script>
export default {
    name: 'CommunicationSettings',

    props: {
        responseRequired: {
            type: Boolean,
            required: true
        },
        translateForm: {
            type: Boolean,
            required: true
        },
    },

    data() {
        return {
            localResponseRequired: true,
            localTranslateForm: false,
        };
    },

    computed: {
        guardianReportingV3Display() {
            return this.$store.getters['flags/flags']['e-c-guardian-notifications-v-3-filters-and-resends']
        },
    },

    mounted() {
        this.localResponseRequired = this.responseRequired
        this.localTranslateForm = this.translateForm

        const responseRequiredCheckbox = this.$refs.responseRequiredCheckbox.$el.querySelector('.v-input__slot')
        if (responseRequiredCheckbox) {
            responseRequiredCheckbox.setAttribute('data-test', 'response-required-checkbox')
        }

        const translateFormCheckbox = this.$refs.translateFormCheckbox.$el.querySelector('.v-input__slot')
        if (translateFormCheckbox) {
            translateFormCheckbox.setAttribute('data-test', 'translate-form-checkbox')
        }
    },

    methods: {
        emitResponseRequired() {
            this.$emit('response-required-changed', this.localResponseRequired)
        },
        emitTranslateForm() {
            this.$emit('translate-form-changed', this.localTranslateForm)
        },
    },
}
</script>
