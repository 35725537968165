<template>
    <fe-card
        class="alt-card ml-2 mt-2"
        headerText="Latest Assessment Scores"
    >
        <template #title-toolbar>
            <v-btn icon @click="showHistorical()">
                <fe-tooltip tooltip="Historical Data">
                    <v-icon small>fas fa-chart-line</v-icon>
                </fe-tooltip>
            </v-btn>
        </template>

        <template v-slot:body>
            <div>
                <tabs ref="tabs" :tabs="tabs" v-model="selectedCategory"/>

                <div class="category-container" v-for="category in showCategories" :key="`test-`+category.name">
                    <div class="d-flex">
                        <div class="category-title flex-grow-1 flex-shrink-1">{{ category.name }}</div>
                        <v-btn icon @click="showHistorical(category)">
                            <fe-tooltip tooltip="Historical Data">
                                <v-icon small>fas fa-chart-line</v-icon>
                            </fe-tooltip>
                        </v-btn>
                    </div>

                    <div class="d-flex flex-wrap">
                        <div v-for="(sub, si) in category.subCategories" :key="`score-group-`+si">
                            <div class="sub-category">
                                <div class="sub-category-title">
                                    {{ sub.sub_category_name }}
                                </div>

                                <div class="d-flex">
                                    <div
                                        v-for="(score, x) in sub.scores"
                                        :key="`scores-`+x"
                                        style="cursor: pointer"
                                        @click="$emit('score-click', score, sub.sub_category_name)"
                                    >
                                        <fe-tooltip :tooltip="score.target_descriptor_name" :disabled="!score.target_descriptor_name">
                                            <div
                                                class="score-container"
                                                :style="[{'background-color': score.target_descriptor_color ? score.target_descriptor_color : '#A6A6A6' }]"
                                            >
                                                <div
                                                    style="font-size: 10px;"
                                                    :style="[{'color': contrastColor(score.target_descriptor_color) }]"
                                                >
                                                    {{ score.data_point_name }}
                                                </div>
                                                <div
                                                    style="font-size: 12px;"
                                                    :style="[{'color': contrastColor(score.target_descriptor_color) }]"
                                                >
                                                    {{ getScore(score) }}
                                                </div>
                                            </div>
                                        </fe-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <fe-crud
                ref="crud"
                :config="$models.targetScores"
                :autoload="false"
                @read="items = $event"
            />
        </template>
    </fe-card>
</template>

<script>
import Tabs from '@/components/common/button/Tabs'

export default {
    name: 'StudentScoreGroup',

    components: {
        Tabs,
    },

    props: ['student'],

    data() {
        return {
            items: [],
            categories: [],
            selectedCategory: {
                name: 'All'
            }
        }
    },

    computed: {
        showCategories() {
            if (this.selectedCategory.name === 'All') return this.categories

            return this.categories.filter(rec => rec.name === this.selectedCategory.name)
        },

        tabs() {
            return [{name: 'All'}].concat(this.categories)
        }
    },

    watch: {
        tabs(v) {
            if (!(this.selectedCategory?.name && v.find(itm => itm.name === this.selectedCategory.name))) {
                this.selectedCategory = {name: 'All'}
                if (this.$refs.tabs) this.$refs.tabs.setTab(this.selectedCategory)
            }
        },

        student: {
            deep: true,
            handler() {
                this.loadData()
            }
        },

        items(v) {
            this.categories = []
            let tmp = {}
            if (v) {
                v.forEach(rec => {
                    if (!tmp[rec.category_name]) tmp[rec.category_name] = {
                        id: rec.category_id,
                        name: rec.category_name,
                        subCategories: []
                    }

                    tmp[rec.category_name].subCategories.push(rec)
                })
            }

            for (let category in tmp) {
                this.categories.push(tmp[category])
            }
        }
    },

    mounted() {
        if (this.student && this.student.student_id) {
            this.loadData()
        }
    },

    methods: {
        getScore(v) {
            // use Score Display setting in Manage > Assessments to determine if we show score or letter grade
            if (typeof v.score === 'string') return v.score
            return this.$getAlphaScore(v.data_point_type_id, v.score, v.sub_category_id)
        },

        contrastColor(bgColor) {
            if (!bgColor || bgColor === '#') {
                return "#000000";
            }

            let nThreshold = 105;
            let fn = function getRGBComponents(color) {
                let r = color.substring(1, 3);
                let g = color.substring(3, 5);
                let b = color.substring(5, 7);

                return {
                    R: parseInt(r, 16),
                    G: parseInt(g, 16),
                    B: parseInt(b, 16)
                };
            };
            let components = fn(bgColor);
            let bgDelta = (components.R * 0.299) + (components.G * 0.587) + (components.B * 0.114);

            return ((255 - bgDelta) < nThreshold) ? "#000000" : "#ffffff";
        },

        loadData() {
            this.$refs.crud.read({
                property: 'summary',
                student_id: this.student.student_id,
                school_year_id: this.student.school_year_id
            })
        },

        showHistorical(category) {
            this.$store.commit('global/addDockableWindow', {
                name: 'Historical Scores',
                component: 'historical-scores',
                attrs: {
                    student: this.student,
                    category: category ? category : null
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.category-container {
    background: #FFFFFF;
    border: 1px solid #E0E1E6;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px 14px 12px 14px;
    margin-bottom: 16px;


    .category-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #2B3963;
        margin: 12px 12px 12px 14px;
    }
}

.sub-category {
    background: #ECEDF1;
    border-radius: 5px;
    padding: 5px 8px 5px 8px;
    margin: 8px;

    .sub-category-title {
        font-size: 14px;
    }

    .score-container {
        min-width: 48px;
        border-radius: 4px;
        padding: 8px;
        margin: 2px;
        text-align: center;
    }
}
</style>
