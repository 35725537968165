<template>
    <div class="container">
        <div>
            <a @click="$router.push('/smartFORMS')">
                <v-icon class="mr-2">fas fa-chevron-left</v-icon>
                Back to smartFORMS
            </a>

            <h1>Email smartFORMS to Guardians</h1>
        </div>

        <div class="bar">
            <!-- Review Email -->
            <div class="segment" :class="{ active: step === 0, completed: step > 0 }" @click="goToStep(0)">
                <i class="far fa-check" v-if="step > 0" style="margin-right: 6px;"></i>
                Review guardians & forms
            </div>
            <div class="triangle-right" :class="{ active:  step === 0, completed: step > 0 }"></div>

            <!-- Choose Settings -->
            <div class="triangle-left" :class="{ active: step === 1 }"></div>
            <div class="segment" :class="{ active: step === 1, completed: step > 1 }" @click="goToStep(1)">
                <i class="far fa-check" v-if="step > 1" style="margin-right: 6px;"></i>
                Choose settings
            </div>
            <div class="triangle-right" :class="{ active: step === 1, completed: step > 1 }"></div>

            <!-- Preview Email -->
            <div class="triangle-left" :class="{ active: step === 2 }"></div>
            <div class="segment" :class="{ active: step === 2, completed: step > 2 }" @click="goToStep(2)">
                <i class="far fa-check" v-if="step > 2" style="margin-right: 6px;"></i>
                Preview email & more
            </div>
            <div class="triangle-right" :class="{ active: step === 2, completed: step > 2 }"></div>

            <!-- Send Email -->
            <div class="triangle-left" :class="{ active: step === 3 }"></div>
            <div class="segment" :class="{ active: step === 3 }">Review summary & Send</div>
        </div>
        <review
            v-if="step === 0"
            :students-count="studentsCount"
            :guardian-contacts-count="guardianContactsCount"
            :smartforms-count="smartFormsCount"
            :column-defs="columnDefs"
            :sending-row-data="sendingRowData"
            :missing-contacts-count="missingContactsCount"
            :missing-guardian-row-data="missingGuardianRowData"
            :hasGuardianEmail="hasGuardianEmail"
            :missing-contact-selections="missingContactSelections"
            @goToCommunicationSettings="goToCommunicationSettings"
        />
        <settings
            v-else-if="step === 1"
            :response-required="responseRequired"
            :translate-form="translateForm"
            @goToPreview="goToPreview"
            @goToReview="goToReview"
            @response-required-changed="updateResponseRequired"
            @translate-form-changed="updateTranslateForm" 
        />
        <preview
            v-else-if="step === 2"
            :expiration-date="getLongExpirationDate()"
            :short-expiration-date="getShortExpirationDate()"
            :short-current-date="getShortCurrentDate()"
            @goToCommunicationSettings="goToCommunicationSettings"
            @goToSend="goToSend"
        />
        <send
            v-else
            :expiration-date="getLongExpirationDate()"
            :students-count="studentsCount"
            :guardian-contacts-count="guardianContactsCount"
            :smartforms-count="smartFormsCount"
            :response-required="responseRequired"
            :form-instances="formInstances"
            :translate-form="translateForm"
            @goToPreview="goToPreview"
        />
    </div>
</template>
<script>
import Review from './Review.vue'
import Settings from './Settings.vue'
import Preview from './Preview.vue'
import Send from './Send.vue'

export default {
    name: 'GuardianEmailSetup',

    components: {
        Review,
        Settings,
        Preview,
        Send,
    },

    data() {
        return {
            studentsCount: 0,
            guardianContactsCount: 0,
            smartFormsCount: 0,
            missingContactsCount: 0,
            columnDefs: [
                {headerName: "Student", field: "student"},
                {headerName: "Guardian Contacts", field: "guardianContacts", width: 400},
                {headerName: "# of Forms", field: "studentFormCount", width: 112, cellClass: 'ag-right-aligned-cell'},
                {headerName: "smartFORMS", field: "smartforms", width: 420},
            ],
            sendingRowData: [],
            missingGuardianRowData: [],
            step: 0,
            responseRequired: true,
            hasGuardianEmail: false,
            missingContactSelections: [],
            formInstances: [],
            translateForm: false,
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            Object.assign(vm.$data, vm.$options.data.call(vm))
            vm.loadSetupData()
        })
    },

    beforeRouteUpdate(to, from, next) {
        Object.assign(this.$data, this.$options.data.call(this))
        this.loadSetupData()
        next()
    },

    beforeRouteLeave(to, from, next) {
        Object.assign(this.$data, this.$options.data.call(this))
        next()
    },

    methods: {
        loadGuardianFormEmailSetup(formIds, callback) {
            this.$modelGet('guardianFormEmailSetup', {form_instances: formIds})
                .then(response => callback(response))
                .catch(err => console.warn('Unable to load guardian form email setup', err))
        },

        loadSetupData() {
            const formIds = this.$route.query.forms

            if (!formIds) return

            const selections = JSON.parse(this.$route.query.selections || '[]')

            this.loadGuardianFormEmailSetup(formIds, (setupData) => {
                if (!setupData) {
                    return
                }

                this.getSetupData(setupData)
                this.missingContactSelections = this.getMissingContactSelections(setupData, selections)
                this.formInstances = []

                Object.values(setupData.students).forEach(student => {
                    this.formInstances = this.formInstances.concat(student.form_instances)
                })
            })
        },

        getSetupData(setupData) {
            Object.values(setupData.students).forEach(student => {
                let studentHasGuardianEmail = false

                if (student.guardians.length) studentHasGuardianEmail = student.guardians.map(guardian => guardian.guardian_email_addr).length > 0
                const studentGuardianContacts = student.guardians.map(guardian => guardian.guardian_email_addr).filter(email => email)


                const studentData = {
                    student: student.student_full_name,
                    guardianContacts: studentHasGuardianEmail ? studentGuardianContacts : 'No email available',
                    studentFormCount: student.form_instances.length,
                    smartforms: student.form_names.join(', '),
                }

                if (!studentHasGuardianEmail) {
                    this.missingContactsCount++
                    this.missingGuardianRowData.push(studentData)
                } else {
                    this.guardianContactsCount += studentGuardianContacts.length
                    this.sendingRowData.push(studentData)
                    this.hasGuardianEmail = true
                }
                this.studentsCount = this.sendingRowData.length
                this.smartFormsCount = student.form_names.length
            })
        },

        goToStep(number) {
            if (this.step > number) {
                this.step = number
            }
        },

        goToReview() {
            this.step = 0
        },

        goToCommunicationSettings() {
            this.step = 1
        },

        goToPreview() {
            this.step = 2
        },

        goToSend() {
            this.step = 3
        },

        updateResponseRequired(value) {
            this.responseRequired = value
        },

        getLongExpirationDate() {
            const today = new Date()
            const nextWeek = new Date(today)
            nextWeek.setDate(today.getDate() + 7)
            return nextWeek.toLocaleDateString('en-US', {month: 'long', day: '2-digit', year: 'numeric'})
        },

        getShortExpirationDate() {
            const today = new Date()
            const nextWeek = new Date(today)
            nextWeek.setDate(today.getDate() + 7)
            return nextWeek.toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year: '2-digit'})
        },

        getShortCurrentDate() {
            const today = new Date()
            return today.toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year: '2-digit'})
        },

        getMissingContactSelections(setupData, selections) {
            const students = setupData.students

            // Filter selections to find those with missing guardian email addresses
            const missingContactSelections = selections.filter(selection => {
                const student = students[selection.student_id]
                if (student && student.guardians.length > 0) {
                    // Check if any guardian has an email address
                    return !student.guardians.some(guardian => guardian.guardian_email_addr)
                }
                // If no guardians, consider it as missing contact
                return true
            })

            return missingContactSelections
        },
        updateTranslateForm(value) {
            this.translateForm = value
        },
    },
}
</script>

<style scoped>
.container {
    position: relative;
    margin: 16px;
}

.container > div > a {
    text-decoration: none;
}

.container > div > h1 {
    margin-top: 16px;
}

.segment {
    flex: 1;
    text-align: center;
    height: 48px;
    font-size: 14px;
    font-family: CerebriSans-Regular, Cerebri Sans, Roboto, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: 400;
    background-color: #ECEDF1;
}

.segment.active {
    background-color: #E5F0F4;
}

.segment.completed {
    cursor: pointer;
}

.bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 10px;
    margin-left: -8px;
    margin-top: 32px;
    margin-bottom: 28px;
    width: 92vw;
}

.triangle-right {
    width: 0;
    height: 0;
    margin-right: 23px;
    border-top: 24px solid white;
    border-left: 15px solid #ECEDF1;
    border-bottom: 24px solid white;
}

.triangle-left {
    width: 0;
    height: 0;
    border-top: 24px solid #ECEDF1;
    border-left: 15px solid transparent;
    border-bottom: 24px solid #ECEDF1;
    margin-left: -32px;
}

.triangle-right.active {
    border-top: 24px solid white;
    border-left: 15px solid #E5F0F4;
    border-bottom: 24px solid white;
}

.triangle-right.completed {
    cursor: pointer;
}

.triangle-left.active {
    border-top: 24px solid #E5F0F4;
    border-left: 15px solid transparent;
    border-bottom: 24px solid #E5F0F4;
}
</style>
