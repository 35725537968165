<template>
    <div class="d-flex flex-fill flex-wrap" style="overflow: scroll;">
        <v-row class="d-flex" style="min-height: 300px;">
            <v-col cols=4>
                <today-card/>
            </v-col>

            <v-col cols=4>
                <intervention-participation-card/>
            </v-col>

            <v-col cols=4>
                <intervention-tools-card/>
            </v-col>
        </v-row>

        <v-row class="d-flex flex-fill" style="min-height: 300px;">
            <v-col cols=4>
                <visualizations-card/>
            </v-col>

            <v-col cols=4>
                <students-card/>
            </v-col>

            <v-col cols=4>
                <quick-links-card/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import LayoutTemplate from '@/components/modules/aatemplate/LayoutTemplate'
    import ProgramEvaluation from '@/components/modules/programevaluation/ProgramEvaluation'
    import Student3d from '@/components/modules/students3d/View'
    import CollectionButton from './CollectionButton'
    import TodayCard from './TodayCard'
    import StudentsCard from './StudentsCard'
    import InterventionParticipationCard from './InterventionParticipationCard'
    import VisualizationsCard from './VisualizationsCard'
    import QuickLinksCard from './QuickLinksCard'
    import InterventionToolsCard from './InterventionToolsCard'
    import { mapState } from 'vuex'

    export default {
        name: 'LaunchPad',
        components: {
            LayoutTemplate,
            ProgramEvaluation,
            CollectionButton,
            Student3d,
            TodayCard,
            StudentsCard,
            InterventionParticipationCard,
            VisualizationsCard,
            QuickLinksCard,
            InterventionToolsCard
        },
        computed: {
            ...mapState('global', ['sessionUser', 'lastLaunchpadVisit', 'dockableWindows']),
            dashboardOnly() {
                return this.sessionUser.district.dashboard_only ? true : false
            }
        },
        mounted() {
            this.setupPanels()
        },
        data() {
            return {
                showDialog: true,
                fieldConfig: [{
                    field: 'name',
                    label: 'Incident Type',
                    idProperty: 'id',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { limit: 50 },
                    width: '100%',
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true,
                        style: {
                            width: '100%'
                        }
                    }
                }, {
                    field: 'created',
                    label: 'Created',
                    idProperty: 'id',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { limit: 20 },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true,
                        readonly: true,
                        style: {
                            width: '100%'
                        }
                    }
                }],
                formRecords: [],
                tools: [],
                incidents: [],
                deeperOptions: [],
                collections: [],
                cards: [{
                    id: 1,
                    title: 'Action Items'
                }, {
                    id: 2,
                    title: 'My Students'
                }, {
                    id: 3,
                    title: 'Timeline'
                }, {
                    id: 4,
                    title: 'My Collections'
                }]
            }
        },
        watch: {
            dockableWindows(v) {
                // If all dockable windows have closed, anticipate that
                // user has just created a new incident (for example) and
                // trigger a refresh of launchpad data
                if (v.length === 0) {
                    this.$store.commit('global/reloadLaunchpad', { })
                }
            },
        },
        methods: {
            go() {
                this.programs.push('program-evaluation')
            },
            setupPanels() {
                let me = this

                this.deeperOptions = [{
                    id: 'layout-templates',
                    title: 'Layout Templates',
                    handler() {
                        me.$router.replace('/LayoutTemplate')
                    }
                }, {
                    id: 'program-evaluation',
                    title: 'Program Evaluation',
                    handler() {
                        me.$router.replace('/ProgramEvaluation')
                    }
                }, {
                    id: 'students-3d',
                    title: 'Students 3D',
                    handler() {
                        me.$router.replace('/Students3D')
                    }
                }, {
                    id: 'analytics',
                    title: 'Analytics',
                    handler() {
                    }
                }]

                this.loadUserData()
            },
            loadUserData() {
                this.$store.dispatch('doQuery', {
                    endpoint: 'dashboard.php?action=get&property=home&_dc=1568385261019&schedule_date_occurrence=2019-09-13',
                    rootProperty: 'dashboard'
                })
                .then(response => {
                    this.collections = response.user_dashboards
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.collection-button {
    height: 72px;
    width: 72px;
    background-color: blue;
}
</style>
