<template>
    <fe-card
        class="alt-card ma-3"
        headerText="Intervention Tools"
    >
        <template v-slot:body>
            <div
                v-for="(item, i) in items"
                class="my-4"
                :key="`intv-item-${i}`"
            >
                <div class="d-flex list-text" @click="itemClicked(item)" :data-test="item.dataTest">
                    <v-icon class="mr-3">{{item.icon}}</v-icon>
                    <div class="text-col">
                        <div>{{item.name}}</div>
                        <div class="text-description"></div>
                    </div>
                    <v-spacer/>
                </div>
            </div>
        </template>
    </fe-card>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'InterventionToolsCard',

        data() {
            return {
                items: [{
                    key: 'intervention-attendance',
                    name: 'Record Participation/Attendance',
                    windowName: 'Record Intervention Participation',
                    component: 'intervention-participation',
                    icon: 'fas fa-calendar',
                    dataTest: 'record-participation-attendance'
                }, {
                    key: 'probe-scores',
                    name: 'Enter Monitoring Scores',
                    windowName: 'Enter Monitoring Scores',
                    component: 'student-monitor-scores',
                    icon: 'fas fa-edit',
                    dataTest: 'enter-monitoring-scores'
                }, {
                    key: 'point-scores',
                    name: 'Enter Point Scores',
                    windowName: 'Point Sheet Monitoring',
                    component: 'point-sheet-monitoring',
                    icon: 'fas fa-edit',
                    dataTest: 'enter-point-scores'
                }, {
                    key: 'my-interventions',
                    name: 'View My Interventions',
                    windowName: 'Intervention Groups',
                    component: 'intervention-workspace',
                    icon: 'fas fa-list',
                    dataTest: 'view-my-interventions'
                }]
            }
        },

        computed: {
            ...mapState('global', ['sessionUser']),

            academicParams() {
                return {
                    monitor_user_id: this.sessionUser.user.id,
                    schedule_date_occurrence: this.$dayjs().format('YYYY-MM-DD'),
                    academic: 1
                }
            },

            attrs() {
                return {
                    'intervention-attendance': {},
                    'probe-scores': {
                        params: this.academicParams,
                        class: 'pa-5'
                    },
                    'point-scores': {},
                    'my-interventions': {
                        id: 433
                    }
                }
            }
        },

        methods: {
            itemClicked(item) {
                if (item.route) {
                    this.$router.push(item.route)
                } else if (item.component) {
                    this.$store.commit('global/addDockableWindow', {
                        name: item.windowName,
                        component: item.component,
                        attrs: this.attrs[item.key],
                        events: {
                            interventionDeleted: () => this.$emit(`${item.component}-updated`)
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .list-text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;

        .text-col {
            .text-description {
                font-size: 80%;
            }
        }

        &:hover {
            font-weight: bold;
        }
    }
</style>
