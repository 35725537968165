<template>
<div>
    <button @click="dialog=true" >{{displayName}}</button>
    <v-dialog v-model="dialog" width="600" :persistent="persistDialog">
        <fe-stepper-filter
            :title="title" style="height:600px;"
            @apply="emit" @close="dialog=false"
            :applyDisabled="!enableFinalPanel"
        >
            <fe-stepper-select-panel :items="demographicItems" v-model="demographic" width="50%" />
            <v-divider vertical style="background-color:#E0E1E6"/>

            <fe-stepper-panel width="50%" style="padding:10px;">
                <template v-if="enableFinalPanel">
                    <fe-remote-combo
                        label="School Year"
                        infoText="If a student was exited at any time during the year, they will be considered exited for the entire year."
                        v-model="panelValues.school_year"
                        rootProperty="years"
                        url="qry.php?action=get&property=school_years"
                    ></fe-remote-combo>
                    <v-layout row>
                        <v-flex class="pr-2">
                            <v-switch v-model="panelValues.restrict" label="Restrict Results" hide-details />
                        </v-flex>
                        <v-flex>
                            <v-switch v-model="panelValues.require_all" label="Require All" hide-details />
                        </v-flex>
                    </v-layout>

                    <advanced-demographic-filter
                        v-model="demographicParams"
                        :demographic="demographic"
                        @result="parseDemographicResult"
                    />

                </template>
            </fe-stepper-panel>

            <template #footer>
                <div class="text-truncate" style="padding:14px">{{breadcrumb}}</div>
            </template>
        </fe-stepper-filter>
    </v-dialog>
</div>
</template>

<script>
import * as Util from '../../Util'
import AdvancedDemographicFilter from "@/components/common/AdvancedDemographicFilter"
import FilterUtil from '../../FilterPanelUtil'

export default {
    name: 'DemographicsPanel',

    rank: 1,

    components: {
        AdvancedDemographicFilter,
    },

    data() {
        return {
            oldFilter: null,
            displayName: 'Demographic',
            type: 'demographic',
            dialog: false,
            panelValues: {
                restrict: true,
                require_all: false,
                school_year: null,
                demographicResult: '',
            },
            demographic: null,
            demographicParams: null,
            persistDialog: false,
            panelOpenState: {}
        }
    },

    watch: {
        dialog(v) {
            if(!v) this.oldFilter = null, this.clear()
            if (v) this.panelOpenState = this.$_.clone(this.panelValues)
        },
        computedPanelValues: {
            handler(newValue) {
                if (Object.keys(this.panelOpenState).length !== 0) {
                    let objectsAreEqual = JSON.stringify(FilterUtil.sortObject(newValue)) === JSON.stringify(FilterUtil.sortObject(this.panelOpenState))
                    this.persistDialog = !objectsAreEqual
                }
            }
        },
    },

    computed: {
        demographicItems: me => me.$store.getters['global/demographicsViewable'],
        title: me => me.oldFilter ? `Edit ${me.displayName} Filter` : `Create ${me.displayName} Filter`,
        enableFinalPanel: me => !me.$_.isEmpty(me.demographic),
        breadcrumb: me => me.demographic?.name,
        computedPanelValues() {
            return Object.assign({}, this.panelValues)
        }
    },

    created() {
        this.$store.dispatch('global/loadDemographics')
    },

    methods: {
        clear() {
            this.panelValues = {
                restrict : true,
                demographicResult : '',
                require_all: false,
                school_year: null
            }
            this.demographic = null
            this.demographicParams = null
        },

        load(oldFilter) {
            this.oldFilter = oldFilter
            this.dialog = true
            let ep = oldFilter.editorParams

            this.panelValues = {
                restrict : ep.restrict,
                demographicResult : ep.demographicResult,
                require_all : ep.require_all,
                school_year : ep.school_year
            }

            this.demographic = ep.demographic
            this.demographicParams = ep.demographicParams
        },

        emit() {
            this.dialog = false
            let uniqueId = Util.UUIDv4()
            let name = this.demographic.name
            let key = `demo_${this.demographic.id}`
            let filter = {
                type: key,
                id: uniqueId,
                restrict: this.panelValues.restrict,
                report: true,
                params: this.$_.pickBy({
                    [key]: this.demographicParams,
                    [uniqueId]: this.demographic.value_type === 'option' ? this.displayNamesWithIds() : null,
                    require_all: this.panelValues.require_all,
                    school_year_id: this.panelValues.school_year?.id,
                }, x => !this.$_.isBlank(x)),
            }
            Object.defineProperties(filter, {
                editor: {value: this},
                editorParams: {value: {
                    restrict: this.panelValues.restrict,
                    demographic: this.demographic ? {...this.demographic} : null,
                    demographicParams: this.demographicParams,
                    demographicResult: this.panelValues.demographicResult ? {...this.panelValues.demographicResult} : null,
                    require_all: this.panelValues.require_all,
                    school_year: this.panelValues.school_year ? {...this.panelValues.school_year} : null,
                }},
                grouper: {value: {
                    id: uniqueId,
                    name: name,
                    type: 'propertyArray',
                    args: {path: `filters.${uniqueId}`, property: 'value'},
                }},
                chipText: {value: name},
                tooltipData: {value: this.$_.pickBy({
                    'Demographic': name,
                    'Params': this.panelValues.demographicResult?.textWithOp,
                    'Restrict': this.panelValues.restrict,
                    'Require All': this.panelValues.require_all,
                    'School Year': this.panelValues.school_year?.name,
                }, x => !this.$_.isBlank(x))}
            })
            this.$emit('input', filter, this.oldFilter)
        },

        parseDemographicResult(e) {
            this.panelValues.demographicResult = ''
            if(e) {
                this.$parseDemographic(e.key,e.encoded).then(r => {
                    this.panelValues.demographicResult = r
                })
            }
        },

        displayNamesWithIds() {
            let options = this.demographic.options
            let names = this.panelValues.demographicResult?.text.split(', ')
            let demo = {}
            names.forEach((name, i) => {
                let option = options.find(o => (o.display_name || o.value) === name)
                demo[i] = {
                    ids: option.display_name_group || option.id,
                    value: option.display_name || option.value
                }
            })
            return demo
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
