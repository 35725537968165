<template>
    <div>
        <button @click="dialog=true">{{ displayName }}</button>
        <v-dialog v-model="dialog" width="600" :persistent="persistDialog">
            <fe-stepper-filter
                :title="title" style="height:600px;"
                @apply="emit" @close="dialog=false"
                :applyDisabled="!enableFinalPanel"
            >
                <fe-stepper-checkbox-panel
                    v-model="panelValues.mealStatuses" :items="mealStatusItems" width="50%"
                />

                <v-divider vertical style="background-color:#E0E1E6"/>

                <fe-stepper-panel width="50%" style="padding:10px;">
                    <template v-if="enableFinalPanel">
                        <fe-remote-combo
                            label="School Year"
                            v-model="panelValues.school_year"
                            rootProperty="years"
                            url="qry.php?action=get&property=school_years"
                        />
                        <v-switch v-model="panelValues.restrict" label="Restrict Results" hide-details/>
                    </template>
                </fe-stepper-panel>

                <template #footer>
                    <div class="text-truncate" style="padding:14px">{{ breadcrumb }}</div>
                </template>
            </fe-stepper-filter>
        </v-dialog>
    </div>
</template>

<script>
import * as Util from '../../Util'
import FilterUtil from '../../FilterPanelUtil'

export default {
    name: 'MealStatusPanel',

    rank: 1,

    security: ['SEARCH_MEAL_STATUS', 'DRILLDOWN_MEAL_STATUS'],

    data() {
        return {
            oldFilter: null,
            displayName: 'Meal Status',
            type: 'meal_status',
            dialog: false,
            mealStatusItems: [],
            panelValues: {
                mealStatuses: [],
                school_year: null,
                restrict: true,
            },
            persistDialog: false,
            panelOpenState: {},
        }
    },

    computed: {
        title() {
            return this.oldFilter ? `Edit ${this.displayName} Filter` : `Create ${this.displayName} Filter`
        },
        enableFinalPanel() {
            return !this.$_.isEmpty(this.panelValues.mealStatuses)
        },
        breadcrumb() {
            return this.panelValues.mealStatuses.map(x => x.display_name || x.name)
                .filter(Boolean)
                .join(', ')
        },
        computedPanelValues() {
            return Object.assign({}, this.panelValues)
        }
    },

    watch: {
        dialog(v) {
            if (!v) this.oldFilter = null, this.clear()
            if (v) this.loadMealStatuses(), this.panelOpenState = this.$_.clone(this.panelValues)
        },
        computedPanelValues: {
            handler(newValue) {
                if (Object.keys(this.panelOpenState).length !== 0) {
                    let objectsAreEqual = JSON.stringify(FilterUtil.sortObject(newValue)) === JSON.stringify(FilterUtil.sortObject(this.panelOpenState))
                    this.persistDialog = !objectsAreEqual
                }
            }
        }
    },

    methods: {
        clear() {
            this.panelValues = {
                restrict: true,
                mealStatuses: [],
                school_year: null,
            }
        },

        load(oldFilter) {
            this.oldFilter = oldFilter
            this.dialog = true
            let ep = oldFilter.editorParams
            this.panelValues.restrict = ep.restrict
            this.panelValues.mealStatuses = ep.mealStatuses
            this.panelValues.school_year = ep.school_year
        },

        emit() {
            this.dialog = false
            let uniqueId = Util.UUIDv4()
            let name = this.panelValues.mealStatuses.map(x => x.display_name || x.name).join(', ')

            // combine ids from singles and groups
            let ids = []
            this.panelValues.mealStatuses.forEach(m => {
                if (!m.display_name_group) {
                    ids.push(m.id)
                } else {
                    ids = ids.concat(m.display_name_group)
                }
                ids = ids.map(i => parseInt(i))
            })

            let filter = {
                type: this.type,
                id: uniqueId,
                restrict: this.panelValues.restrict,
                report: true,
                params: this.$_.pickBy({
                    meal_status_id: ids,
                    school_year_id: this.panelValues.school_year?.id,
                }, x => !this.$_.isBlank(x)),
            }
            Object.defineProperties(filter, {
                editor: {value: this},
                editorParams: {
                    value: {
                        restrict: this.panelValues.restrict,
                        mealStatuses: this.panelValues.mealStatuses ? [...this.panelValues.mealStatuses] : null,
                        school_year: this.panelValues.school_year ? {...this.panelValues.school_year} : null,
                    }
                },
                chipText: {value: name},
                tooltipData: {
                    value: this.$_.pickBy({
                        'Meal Status': name,
                        'Restrict': this.panelValues.restrict,
                        'School Year': this.panelValues.school_year?.name,
                    }, x => !this.$_.isBlank(x))
                }
            })
            this.$emit('input', filter, this.oldFilter)
        },

        loadMealStatuses() {
            this.$axios.get(this.$models.getUrl('mealStatusType', 'read'))
                .then((response) => {
                if (response && response.data) {
                    let data = response.data.meal_status
                    let filtered = []
                    // if available, use display_name
                    data.forEach(itm => {
                        if (!itm.display_name_group) {
                            itm.name = itm.display_name || itm.name
                            filtered.push(itm)
                        } else {
                            let o = data.find(x => x.id == itm.display_name_group[0]) // find and return first option within display_name_group; id can be string
                            if (!filtered.find(x => x.display_name === o.display_name )) {
                                o = {
                                    ...o,
                                    name: o.display_name || o.name // use display_name instead of value
                                }
                                filtered.push(o)
                            }
                        }
                    })
                    this.mealStatusItems = filtered
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped></style>
