<template>
    <div>
        <h3 style="font-size: 18px; margin-bottom: 20px;">Preview Email, Security Questions, & Linked Forms</h3>

        <v-tabs v-model="activeTab">
            <v-tab v-for="(tab, index) in tabs" :key="index">{{ tab.name }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
            <v-tab-item>
                <div class="note">Note: This is a preview of the guardian email message (in English).</div>

                <div class="rounded-div">
                    <img class="image" :src="$axios.defaults.baseURL + 'logo.php'" alt="District Logo">

                    <div style="margin-top: 16px; margin-left: 16px;">
                        Dear parent/guardian of &lt;Student’s First Name&gt;,
                        <br>
                        <br>
                        There are new documents for &lt;Student’s First Name&gt; from Maple Leaf School District ready
                        for you to review. To protect student privacy, you will need to answer a few security questions
                        before viewing the documents.
                    </div>

                    <div v-if="this.responseRequired" style="margin-left: 16px;">
                        <br>
                        <b>Your child’s school wants to know that you’ve seen the documents. Simply select the button at
                            the top of each document after you review it.
                            <br>
                            <br>
                            Document expires on {{ expirationDate }}</b>
                    </div>

                    <fe-btn
                        style="margin-top: 20px; margin-left: 12px;"
                        usage="secondary"
                        disabled
                    >
                        View Documents
                    </fe-btn>

                    <div style="margin-left: 16px;">
                        <br>
                        If you have any difficulty accessing the document, please contact your child’s teacher or school
                        administrator.
                        <div style="margin-bottom: 16px;">
                            <br>
                            Maple Leaf School District
                        </div>
                    </div>
                </div>
            </v-tab-item>

            <v-tab-item>
                <div class="note">Note: These security questions open on a secure page in the guardian’s browser.</div>

                <div class="rounded-div">
                    <img class="image" :src="$axios.defaults.baseURL + 'logo.php'" alt="District Logo">

                    <div style="width: 76%;">
                        <div>
                            <h4 style="margin-top: 24px;">Review Student Document</h4>
                            Answer the following questions to view the documents.
                            <br>
                            <br>
                            What is &ltStudent’s First Name&gt date of birth? Example: 5 4 2015
                        </div>

                        <div class="form-fields">
                            <!-- Date Row -->
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">Month</div>
                                    <div class="input-field"></div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">Day</div>
                                    <div class="input-field"></div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">Year</div>
                                    <div class="input-field"></div>
                                </div>
                            </div>

                            <!-- School Row -->
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="form-group">What school does &lt;Student's First Name&gt; attend?</div>
                                    <div class="input-field"></div>
                                </div>
                            </div>

                            <!-- Grade Level Row -->
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="form-group">What is &lt;Student's First Name&gt; grade level during the
                                        2023-24 school year?
                                    </div>
                                    <div class="input-field"></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </v-tab-item>

            <v-tab-item>
                <div class="note">
                    Note: After a guardian successfully answers the security questions, they see this to view the form(s) from a secure page in the browser.
                </div>

                <div class="rounded-div">
                    <img class="image" :src="$axios.defaults.baseURL + 'logo.php'" alt="District Logo">

                    <h4 style="margin-top: 20px; margin-left: 16px;">1 document was shared with you on {{ shortCurrentDate }}</h4>

                    <div class="document-box">
                        <div class="inner-document-box">
                            <div class="document-details">
                                <h4>aReading Assessment Results</h4>

                                <span>
                                    <i style="color: #BF0D00; margin-bottom: -4px;" class="fas fa-exclamation-circle"></i>
                                    Review and respond by {{ shortExpirationDate }}.
                                </span>
                            </div>

                            <span>
                                <fe-btn usage="secondary" disabled>
                                    View Document
                                </fe-btn>
                            </span>
                        </div>
                    </div>
                </div>
            </v-tab-item>
        </v-tabs-items>

        <fe-btn
            data-test="preview-back-btn"
            style="margin-top: 20px; margin-left: -4px;"
            usage="secondary"
            @click="$emit('goToCommunicationSettings')"
        >
            Back
        </fe-btn>

        <fe-btn
            data-test="preview-continue-btn"
            style="margin-top: 20px; margin-left: -4px;"
            usage="primary"
            @click="$emit('goToSend')"
        >
            Continue
        </fe-btn>
    </div>
</template>

<script>
export default {
    name: 'PreviewEmail',

    props: {
        expirationDate: {
            type: String,
            required: true
        },
        shortExpirationDate: {
            type: String,
            required: true
        },
        shortCurrentDate: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            tabs: [{
                name: 'Email Message',
            }, {
                name: 'Security Questions',
            }, {
                name: 'Linked smartFORMS',
            }],
            activeTab: 0,
            responseRequired: true,
        }
    },

    created() {
        this.activeTab = this.tabs.findIndex(tab => tab.name === 'Email Message')
    },
}
</script>

<style>
.rounded-div {
    border: 1.5px solid #ECEDF1;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    color: #495B8F;
    width: 80%;
    min-height: 418px;
}

.input-field {
    height: 36px;
    border: 1.5px solid #ECEDF1;
    border-radius: 6px;
}

.form-fields {
    margin-left: -10px;
}

.note {
    margin-top: 24px;
    margin-bottom: 12px;
}

.image {
    max-width: 15%;
    height: auto;
    margin-left: 16px;
    margin-top: 24px;
}

.document-box {
    border: 1.5px solid #ECEDF1;
    border-radius: 6px;
    padding: 12px;
    margin: 16px;
    width: 80%;
    align-items: center;
}

.inner-document-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.document-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 6px;
}

.v-tab {
    text-transform: none !important;
}
</style>
